import React from "react";

export default function DashboardFooter() {
  return (
    <div>
      <h1 className="font-medium text-sm text-center text-gray-400">
        Copyright Algoo Strategies 2024.
      </h1>
    </div>
  );
}
