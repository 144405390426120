import { jwtDecode } from "jwt-decode";
import { createContext, useReducer, useEffect } from "react";

export const AuthContext = createContext();

export function authReducer(state, action) {
  switch (action.type) {
    case "LOGIN":
      return {
        ...state,
        user: action.payload,
      };
    case "LOGOUT":
      return {
        ...state,
        user: null,
      };
    default:
      return state;
  }
}

export const AuthContextProvider = ({ children }) => {
  const user =
    localStorage.getItem("user") && JSON.parse(localStorage.getItem("user"));
  const token = localStorage.getItem("access");
  const [state, dispatch] = useReducer(authReducer, {
    user,
    token,
  });
  useEffect(() => {
    if (user) {
      dispatch({ type: "LOGIN", payload: user });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
