import React from "react";

const QuestionMark = () => {
  return (
    <div>
      <div class="border inline-flex justify-center cursor-pointer items-center border-gray-400 text-gray-300 text-xs font-semi-bold border-solid w-5 h-5 rounded-full select-none ml-1 align-middle">
        ?
      </div>
    </div>
  );
};

export function HelperTooltip({ message }) {
  return (
    <div className="absolute -left-5 -top-14 bg-gray-700 text-gray-300 z-50 w-[12rem] lg:w-[16rem] whitespace-normal rounded-md p-4 text-left text-sm leading-[1.25rem] font-normal shadow-xl">
      <p className="text-gray-300">{message}</p>
    </div>
  );
}

export default QuestionMark;
