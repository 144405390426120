import React, { useState } from "react";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Success = () => {
  const [open, setOpen] = useState(true);
  const [txnId, setTxnId] = useState("");
  const [success, setSuccess] = useState(false);

  function sendMail(id) {
    setSuccess(true);
  }

  return (
    open && (
      <div>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 bg-gray-700 bg-opacity-50 z-[9999] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full"
        >
          <div className="relative w-full h-full mx-auto top-[25%] max-w-xl md:h-auto">
            <div className="relative bg-[#181b20] z-[9999] opacity-100 rounded-lg shadow">
              <div className="flex items-start justify-between p-4 border-b border-b-gray-400 rounded-t">
                <h3 className="text-xl font-semibold text-gray-300">Deposit</h3>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="text-sm p-1.5 ml-auto inline-flex items-center text-gray-400"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="px-6 py-5 text-gray-400">
                <div className="">
                  <h1 className="font-medium text-lg md:text-xl text-center">
                    Verify the transaction
                  </h1>
                </div>
                <p className="mt-2 text-sm">
                  Please enter the transaction ID below so that we can cconfirm
                  your transaction. After confirmation, the funds will be
                  deposited into your account wihin the next 24 hours.
                </p>
                <div className="mt-3 space-y-4">
                  <div className="py-1">
                    <p className="text-base leading-relaxed text-gray-400">
                      Transaction ID
                    </p>
                    <div>
                      <input
                        onChange={(e) => {
                          setTxnId(e.target.value);
                        }}
                        type="text"
                        className="bg-[#0a0c0f] border text-gray-300 outline-none border-gray-400 mt-1 py-3 rounded-sm w-full"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center px-6 py-4 space-x-2 border-t border-gray-200 rounded-b">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={sendMail}
                  className="bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
        <div>
          {success === true && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="success">
                The transaction ID has been sent successfully
              </Alert>
            </Snackbar>
          )}
          {success === false && (
            <Snackbar open={true} autoHideDuration={5000}>
              <Alert severity="error">
                Sorry, an error occured. Please try again later
              </Alert>
            </Snackbar>
          )}
        </div>
      </div>
    )
  );
};

export default Success;
