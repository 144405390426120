import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import client from "../../utils/client";
import truncateText from "../../utils/truncate-text";
import Table from "./Table";

const WithdrawHistoryComponent = () => {
  const [withdrawals, setWithdrawals] = useState([]);
  const { user } = useContext(AuthContext);

  async function getWithdrawals() {
    try {
      const { data } = await client({
        url: `/txns/withdrawal?id=${user._id}`,
        method: "GET",
      });
      setWithdrawals(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getWithdrawals();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {withdrawals.length > 0 ? (
        <Table
          columns={[
            {
              title: "Asset",
              render: (data) => (
                <h1 className="light-grey">{data.asset.toUpperCase()}</h1>
              ),
            },
            {
              title: "Network",
              render: (data) => <h1 className="light-grey">{data.network}</h1>,
            },
            {
              title: "Amount",
              render: (data) => <h1 className="light-grey">{data.amount}</h1>,
            },
            {
              title: "Wallet address",
              render: (data) => (
                <h1 className="light-grey">{data.walletAddress}</h1>
              ),
            },
            {
              title: "Date",
              render: (data) => (
                <h1 className="light-grey">
                  {truncateText(data.createdAt, 10)}
                </h1>
              ),
            },
          ]}
          data={withdrawals}
        />
      ) : (
        <div>
          <p className="text-center text-gray-300">No available data</p>
        </div>
      )}
    </div>
  );
};

export default WithdrawHistoryComponent;
