import React, { useState, useEffect } from "react";
import logo from "../../assets/logo.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import client from "../../utils/client";

const WithdrawComponent = ({ nextStep, handleChange, values }) => {
  const [open, setOpen] = useState(true);
  const [coins, setCoins] = useState([]);
  const [coinChosen, setCoinChosen] = useState(null);
  const nextPage = (e) => {
    e.preventDefault();
    nextStep();
  };

  async function getCoins() {
    try {
      const { data } = await client({ url: `/txns/coins`, method: "GET" });
      setCoins(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCoins();
  }, []);

  return (
    open && (
      <div className="">
        <div
          id="defaultModal"
          // tabIndex="-1"
          // aria-hidden="true"
          className="fixed top-0 left-0 right-0 bg-gray-700 bg-opacity-50 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full"
        >
          <div className="relative w-full h-full mx-auto top-[10%] max-w-xl md:h-auto">
            <div className="relative bg-[#181b20] z-[9999] opacity-100 rounded-lg shadow">
              <div className="flex items-start justify-between p-4 border-b border-b-gray-400 rounded-t">
                <h3 className="text-xl font-semibold text-gray-300">
                  Withdraw
                </h3>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="text-sm p-1.5 ml-auto inline-flex items-center text-gray-400"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="px-6 space-y-6">
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    From
                  </p>
                  <div className="flex items-center space-x-3">
                    <img
                      alt="logo"
                      className="w-[50px] h-[60px] p-2"
                      src={logo}
                    />
                    <h1 className="text-gray-300 font-medium text-lg">
                      Algoo Strategies
                    </h1>
                  </div>
                </div>
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Asset to withdraw
                  </p>
                  <div>
                    <FormControl fullWidth>
                      <Select
                        // defaultValue={values.assetChosen}
                        className="bg-[#0a0c0f] border border-gray-400 mt-1 z-[9999]"
                        onChange={handleChange("assetChosen")}
                      >
                        {coins.map((coin) => {
                          return (
                            <MenuItem
                              onClick={() => setCoinChosen(coin)}
                              value={coin.name}
                              className="flex items-center space-x-3 bg-[#0a0c0f]"
                            >
                              <div>
                                <img
                                  className="w-5 h-5"
                                  alt="asset_logo"
                                  src={coin.image}
                                />
                              </div>
                              <div>
                                <p className="font-bold text-gray-600">
                                  {coin.name}
                                </p>
                              </div>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Network
                  </p>
                  <div>
                    <FormControl fullWidth>
                      <Select
                        disabled={values.assetChosen === "null" ? true : false}
                        className="bg-[#0a0c0f] border border-gray-400 mt-1"
                        onChange={handleChange("networkChosen")}
                      >
                        {coinChosen &&
                          coinChosen.networks.map((network) => {
                            return (
                              <MenuItem
                                value={network}
                                className="flex items-center space-x-3 bg-[#0a0c0f]"
                              >
                                <div>
                                  <p className="font-bold text-gray-600">
                                    {network}
                                  </p>
                                </div>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Amount
                  </p>
                  <div>
                    <input
                      onChange={handleChange("amount")}
                      type="number"
                      className="bg-[#0a0c0f] border text-gray-300 outline-none border-gray-400 mt-1 py-3 rounded-sm w-full"
                    />
                  </div>
                </div>
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Wallet Address
                  </p>
                  <div>
                    <input
                      onChange={handleChange("walletAddress")}
                      type="text"
                      className="bg-[#0a0c0f] border text-gray-300 outline-none border-gray-400 mt-1 py-3 rounded-sm w-full"
                    />
                  </div>
                </div>
              </div>
              <div></div>
              <div className="flex items-center p-6 space-x-2 border-t mt-5 border-gray-200 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={nextPage}
                  className="bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default WithdrawComponent;
