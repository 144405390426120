import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import debank from "../assets/debabk.png";
import client from "../utils/client";

const LeaderboardTable = () => {
  const [pools, setPools] = useState([]);
  const navigate = useNavigate();

  async function getPools() {
    try {
      const { data } = await client({ url: "/txns/pools", mthod: "GET" });
      setPools(data.data.slice(0, 5));
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPools();
  }, []);
  return (
    <div>
      {pools &&
        pools.map((pool) => {
          return (
            <div className="sm:grid grid-cols-5 gap-8 bg-[#181b20] my-3 rounded-2xl sm:rounded-full py-4 px-8 hover:opacity-75 duration-300">
              <div className="flex items-center justify-between col-span-2">
                <div className="flex items-center space-x-3">
                  <div>
                    <img
                      className="rounded-full w-10 h-10"
                      src={pool.image}
                      alt="strategy"
                    />
                  </div>
                  <div>
                    <h1 className="text-gray-400">{pool.name}</h1>
                  </div>
                </div>
                <div className="sm:hidden">
                  <p className="text-xs text-gray-400">Risk Level</p>
                  <h1
                    className={`py-[3px] px-4 bg-gray-900 border text-xs mt-1 ${
                      parseInt(pool.riskFactor.split("/")[0]) >= 2
                        ? "border-red-600 text-red-600"
                        : "border-green-600 text-green-600"
                    } rounded-xl`}
                  >
                    {pool.riskFactor}
                  </h1>
                </div>
              </div>
              {/* <div className="h-[2px] bg-gray-700 rounded-full sm:hidden w-full my-2 border"></div> */}
              <div className="max-[640px]:flex items-center justify-between max-[640px]:py-3">
                <p className="text-lg sm:text-xs text-gray-400 font-medium">
                  Expected 1y ROI
                </p>
                <h1 className="text-xl text-gray-300">{pool.yearlyReturn} %</h1>
              </div>
              <div className="flex items-center justify-between">
                <div className="max-[640px]:flex items-center justify-between">
                  <p className="text-lg sm:text-xs text-gray-400 font-medium">
                    Manager
                  </p>
                  <h1 className="text-xl text-gray-300">{pool.manager}</h1>
                </div>
                <div className="max-[640px]:hidden">
                  <p className="text-xs text-gray-400 font-medium">
                    Risk Level
                  </p>
                  <h1
                    className={`py-[3px] px-4 bg-gray-900 border text-xs mt-1 ${
                      pool.riskFactor.split("/")[0] >= 2
                        ? "border-red-600 text-red-600"
                        : "border-green-600 text-green-600"
                    } rounded-xl`}
                  >
                    {pool.riskFactor}
                  </h1>
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="max-[640px]:my-2 items-center justify-between">
                  <div className="bg-[rgba(255,255,255,.12)] p-2 rounded-lg ">
                    <a
                      className="flex items-center space-x-2"
                      href={pool.strategyAddress}
                    >
                      <img className="w-4 h-4" alt="" src={debank} />
                      <p className="text-gray-300 font-medium text-sm">
                        Strategy Wallet
                      </p>
                    </a>
                  </div>
                </div>
                <div>
                  <button
                    onClick={() => {
                      navigate("/login");
                    }}
                    className="py-3 px-6 max-[640px]:my-3 max-[640px]:w-full text-gray-700 bg-[#b2ffce] rounded-xl sm:rounded-2xl font-medium"
                  >
                    Trade
                  </button>
                </div>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default LeaderboardTable;
