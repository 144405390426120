import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import Loader from "../components/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { AuthContext } from "../context/authContext";
import client from "../utils/client";

const VerifyEmail = () => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  async function verifyUser(e) {
    setLoading(true);
    e.preventDefault();
    client({
      url: `/auth/email-verify`,
      data: {
        otp: code,
        email: user.email,
      },
      method: "POST",
    })
      .then((data) => {
        setLoading(false);
        setMessage(data.data.message);
        setTimeout(() => {
          navigate("/dashboard");
        }, 3000);
      })
      .catch((err) => {
        setLoading(false);
        setError(err.response.data.message);
      });
  }

  return (
    <div>
      <div className="bg-[#0a0c0f] min-h-screen w-full px-8 pt-36 md:pt-0 md:flex justify-center items-center">
        <div>
          <Loader />
        </div>
        <div>
          <div>
            <Link to="/">
              <img className="w-[20%] mb-14 mx-auto" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="md:w-[500px] fadeIn">
            <div className="text-center text-gray-400 mb-9">
              <h1 className="font-medium text-3xl mb-2">
                Verify your email address
              </h1>
              <p>Check your email for a verification code.</p>
            </div>
            <div>
              <form className="space-y-5" onSubmit={(e) => verifyUser(e)}>
                <div>
                  <label
                    htmlFor="code"
                    className="mb-2 text-sm font-medium text-gray-400 block "
                  >
                    Verification Code
                  </label>
                  <input
                    className="w-full bg-gray-500 border text-gray-300 border-gray-700 rounded-md px-4 py-2 outline-none"
                    name="code"
                    required
                    type="number"
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className="w-full bg-[#b2ffce] rounded-md px-4 py-2 outline-none"
                    type="submit"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size="25px" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
              <p className="text-gray-400 text-center mt-3">
                Don't have an account?{" "}
                <Link to="/signup" className="text-[#b2ffce] underline">
                  Signup
                </Link>
              </p>
              <p className="text-gray-400 text-center mt-2">
                Forgot your password?{" "}
                <Link to="/login-twofa" className="text-[#b2ffce] underline">
                  Signin with Two-FA
                </Link>
              </p>
            </div>
          </div>
          <div>
            <p
              onClick={() => navigate("/")}
              className="underline text-gray-400 text-sm mt-8 hover:cursor-pointer"
            >
              Go home
            </p>
          </div>
          <div>
            {message !== null && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="success">{message}</Alert>
              </Snackbar>
            )}
            {error !== null && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;
