import { useState, useEffect } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import client from "../../utils/client";

const TwoFactorAuthentication = ({ authToken }) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState("");
  const [qrCode, setQRCode] = useState(null);
  const [secret, setSecret] = useState("");
  const [code, setCode] = useState("");

  async function getTwoFa() {
    const { data } = await client({
      url: `/auth/get-twofa`,
      method: "GET",
      headers: { Authorization: `Bearer ${authToken}` },
    });
    setQRCode(data.data.qrcode);
    setSecret(data.data.secret.base32);
  }

  useEffect(() => {
    getTwoFa();
  });

  async function handleVerify() {
    setLoading(true);
    client({
      method: "POST",
      url: `/auth/verify-twofa`,
      data: { token: code, secret },
      headers: { Authorization: `Bearer ${authToken}` },
    })
      .then((res) => {
        setSuccess(true);
        setLoading(false);
      })
      .catch((err) => {
        setMessage(err.response.data.message);
        setSuccess(false);
        setLoading(false);
      });
  }

  return (
    <div>
      <div className="text-center md:grid grid-cols-2 gap-5 md:mt-20">
        <div className="md:bg-gray-800 md:p-20 md:rounded-md">
          <div>
            <h1 className="text-gray-300 font-medium text-lg">Scan QR code</h1>
          </div>
          <div className="my-4">
            {qrCode && (
              <img src={qrCode} alt="rej" className="mx-auto rounded-xl" />
            )}
          </div>
          <div className="my-2 mx-4">
            <p className="text-gray-400 font-medium">Or copy secret key:</p>
            <input
              className="py-[10px] w-full px-4 text-gray-900 block mx-auto mt-5 mb-3 rounded-md outline-none"
              readOnly
              value={secret}
            />
          </div>
          <button
            onClick={() => getTwoFa()}
            className="py-3 bg-[#b2ffce] text-gray-800 px-8 rounded-md font-medium"
          >
            Generate new secret key
          </button>
        </div>
        <div className="max-md:mt-9 md:bg-gray-800 md:p-20 md:rounded-md md:flex items-center justify-center">
          <div>
            <label className="text-gray-300 font-medium text-lg block">
              Enter code from your Authenticator app:
            </label>
            <input
              className="py-[10px] w-52 text-gray-900 block mx-auto mt-5 mb-3 rounded-md"
              type="text"
              onChange={(e) => setCode(e.target.value)}
            />
            <button
              className="py-2 bg-[#b2ffce] text-gray-800 px-8 rounded-md font-medium"
              onClick={handleVerify}
            >
              {loading ? (
                <CircularProgress color="inherit" size={25} />
              ) : (
                "Verify"
              )}
            </button>
          </div>
        </div>
      </div>
      <div>
        {success === true && (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="success">
              Two-Factor Authentication setup successful ✅
            </Alert>
          </Snackbar>
        )}
        {success === false && (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="error">{message}</Alert>
          </Snackbar>
        )}
      </div>
    </div>
  );
};

export default TwoFactorAuthentication;
