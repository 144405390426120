import React, { useEffect } from "react";
import useScript from "../../hooks/useScript";
import client from "../../utils/client";

export default function CryptocurrencyConverter({ currency }) {
  // const [cryptoList, setCryptoList] = useState([]);
  useScript("https://widgets.coingecko.com/coingecko-coin-converter-widget.js");

  useEffect(() => {
    client({ url: "/txns/fixed-yields", method: "GET" })
      .then((response) => {
        console.log(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className="text-white max-md:hidden">
      <h1 className="font-medium text-lg text-gray-300">Converter</h1>
      <div className="mt-5">
        <coingecko-coin-converter-widget
          coin-id={currency}
          currency="usd"
          background-color="#181b20"
          font-color="#fff"
          locale="en"
        ></coingecko-coin-converter-widget>
      </div>
    </div>
  );
}
