import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import useSignup from "../hooks/useSignup";
import Loader from "../components/Loader";
import useQuery from "../hooks/useQuery";

const Signup = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signup, error, loading, success } = useSignup();
  const navigate = useNavigate();
  const query = useQuery();
  const refCode = query.get("ref");

  async function signupUser(e) {
    e.preventDefault();
    let url = `/auth/signup`;
    if (refCode) {
      url = `/auth/signup?ref=${refCode}`;
    }
    await signup(url, name, email, password);
  }
  useEffect(() => {
    success &&
      setTimeout(() => {
        navigate("/verify-email");
      }, 5000);
  }, [success]);
  return (
    <div>
      <div className="bg-[#0a0c0f] min-h-screen w-full px-8 pt-20 pb-32 md:flex justify-center items-center">
        <div>
          <Loader />
        </div>
        <div>
          <div>
            <Link to="/">
              <img className="w-[20%] mb-14 mx-auto" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="md:w-[500px] fadeIn">
            <div className="text-center text-gray-400 mb-9">
              <h1 className="font-medium text-3xl mb-2">Create an account</h1>
              <p>Let's get you started.</p>
            </div>
            <div>
              <form className="space-y-5" onSubmit={signupUser}>
                <div>
                  <label
                    htmlFor="name"
                    className="mb-2 text-sm font-medium text-gray-400 block "
                  >
                    Name (required)
                  </label>
                  <input
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    className="w-full bg-gray-500 border text-gray-300 border-gray-700 rounded-md px-4 py-2 outline-none"
                    name="name"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="mb-2 text-sm font-medium text-gray-400 block "
                  >
                    Email (required)
                  </label>
                  <input
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    className="w-full bg-gray-500 border text-gray-300 border-gray-700 rounded-md px-4 py-2 outline-none"
                    name="email"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="password"
                    className="mb-2 text-sm font-medium text-gray-400 block "
                  >
                    Password (required)
                  </label>
                  <input
                    onChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    className="w-full bg-gray-500 border text-gray-300 border-gray-700 rounded-md px-4 py-2 outline-none"
                    name="password"
                    required
                    type="password"
                  />
                </div>
                <div>
                  <button
                    disabled={loading}
                    className="w-full disabled:cursor-not-allowed bg-[#b2ffce] rounded-md px-4 py-2 outline-none"
                    type="submit"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size="25px" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
              <p className="text-gray-400 text-center mt-7">
                Already have an account?{" "}
                <Link to="/login" className="text-[#b2ffce] underline">
                  Login
                </Link>
              </p>
            </div>
          </div>
          <div>
            <p
              onClick={() => navigate("/")}
              className="underline text-gray-400 text-sm mt-8 hover:cursor-pointer"
            >
              Go home
            </p>
          </div>
          <div>
            {success === true && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="success">
                  You have successfully signed up! You will be prompted to
                  verify your email next.
                </Alert>
              </Snackbar>
            )}
            {error && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
