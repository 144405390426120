import React from "react";
import { useNavigate } from "react-router-dom";
import Table from "./Table";

const PoolsTable = ({ pools }) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="mt-10 h-[80vh] overflow-y-scroll">
        {pools.length > 0 ? (
          <Table
            columns={[
              {
                title: "",
                render: (data) => (
                  <img
                    className="rounded-full w-10 h-10"
                    src={data.image}
                    alt="strategy"
                  />
                ),
              },
              {
                title: "Strategy Name",
                render: (data) => <h1 className="light-grey">{data.name}</h1>,
              },
              {
                title: "Managed",
                render: (data) => (
                  <h1 className="light-grey">$ {data.valueManaged}</h1>
                ),
              },
              {
                title: "Weekly",
                render: (data) => (
                  <h1 className="light-grey">{data.weeklyReturn} %</h1>
                ),
              },
              {
                title: "All time",
                render: (data) => (
                  <h1 className="light-grey">{data.lifetimeReturn} %</h1>
                ),
              },
              {
                title: "Risk factor",
                render: (data) => (
                  <h1
                    className={`py-[3px] px-4 bg-gray-900 border text-xs mt-1 w-fit ${
                      data.riskFactor.split("/")[0] >= 2
                        ? "border-red-600 text-red-600"
                        : "border-green-600 text-green-600"
                    } rounded-xl`}
                  >
                    {data.riskFactor}
                  </h1>
                ),
              },

              {
                title: "",
                render: (data) => (
                  <button
                    onClick={() => {
                      navigate(`/strategy/${data._id}`);
                    }}
                    className="p-2 px-3 max-[640px]:w-full text-sm text-gray-700 bg-[#b2ffce] rounded-lg font-medium"
                  >
                    View
                  </button>
                ),
              },
            ]}
            data={pools}
          />
        ) : (
          <div>
            <p className="text-center text-gray-300">No available data</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PoolsTable;
