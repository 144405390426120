import React, { useState, useContext, useEffect } from "react";
import { AuthContext } from "../../context/authContext";
import client from "../../utils/client";
import truncateText from "../../utils/truncate-text";
import Table from "./Table";

const InvestmentHistoryComponent = () => {
  const [investments, setInvestments] = useState([]);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    async function getInvestments() {
      try {
        const { data } = await client({
          url: `/txns/investment?id=${user._id}`,
          method: "GET",
        });
        setInvestments(data.data);
      } catch (error) {
        console.log(error);
      }
    }
    getInvestments().catch((err) => console.log({ err }));
  }, [user._id]);

  return (
    <div>
      {investments.length > 0 ? (
        <Table
          columns={[
            {
              title: "Strategy Name",
              render: (data) => <h1 className="light-grey">{data.pool}</h1>,
            },
            {
              title: "Amount",
              render: (data) => <h1 className="light-grey">{data.amount}</h1>,
            },
            {
              title: "Status",
              render: (data) => <h1 className="light-grey">{data.status}</h1>,
            },
            {
              title: "Entry",
              render: (data) => <h1 className="light-grey">{data.entry}</h1>,
            },
            {
              title: "Exit",
              render: (data) => (
                <h1 className="light-grey">
                  {data.exit === "null" ? "-" : data.exit}
                </h1>
              ),
            },
            {
              title: "Date",
              render: (data) => (
                <h1 className="light-grey">
                  {truncateText(data.createdAt, 10)}
                </h1>
              ),
            },
          ]}
          data={investments}
        />
      ) : (
        <div>
          <p className="text-center text-gray-300">No available data</p>
        </div>
      )}
    </div>
  );
};

export default InvestmentHistoryComponent;
