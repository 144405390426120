import React, { useState, useEffect } from "react";
import Chart from "chart.js/auto";

const PieChart = ({ percentages }) => {
  const [chartData, setChartData] = useState({});

  useEffect(() => {
    if (percentages && percentages.length > 0) {
      const data = {
        labels: percentages.map((p) => p.coin),
        datasets: [
          {
            label: "Percentage",
            data: percentages.map((p) => p.percentage),
            backgroundColor: "#33f1798f",
            borderWidth: 1,
          },
        ],
      };
      const options = {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                max: 100,
                callback: (value) => `${value} %`,
              },
              scaleLabel: {
                display: true,
                labelString: "Percentage",
              },
            },
          ],
          xAxes: [
            {
              scaleLabel: {
                display: true,
                labelString: "Label",
              },
            },
          ],
        },
      };
      setChartData({ data, options });
    }
  }, [percentages]);

  useEffect(() => {
    if (chartData) {
      const ctx = document.getElementById("myBarChart").getContext("2d");
      const myChart = new Chart(ctx, {
        type: "bar",
        data: chartData.data,
        options: chartData.options,
      });
      return () => myChart.destroy();
    }
  }, [chartData]);

  return (
    <canvas
      id="myBarChart"
      className="max-w-md max-h-96 text-gray-300"
      width="200"
      height="200"
    ></canvas>
  );
};

export default PieChart;

// const generateFluctuatingData = (daily, weekly, i) => {
//   const amplitude = (weekly - daily) / 2;
//   const offset = daily + amplitude.toFixed;

//   const random = parseInt(parseInt(offset).toFixed(2));
//   console.log({ amplitude: parseInt(amplitude.toFixed(2)) });
//   console.log({ amplitude: parseInt(amplitude.toFixed(2)) });
//   console.log({ amplitude: parseInt(amplitude.toFixed(2)) });

//   let value;
//   if (i % 3 === 0) {
//     value = parseInt(amplitude.toFixed(2)) + random + i;
//   } else {
//     value = random + i * 0.5;
//   }

//   if (Math.abs(value - random) > 10) {
//     value = parseInt(amplitude.toFixed(2)) + i * 100 - 90;
//   }
//   console.log({ daily: parseInt(daily) });

//   console.log([parseInt(daily), value]);
//   console.log({ hi: 2 * Math.PI * i });

//   return value;
// };
