import { useAuthContext } from "./useAuthContext";

const useLogout = () => {
  const { dispatch } = useAuthContext();
  function logout() {
    // remove user from storage
    localStorage.removeItem("user");
    localStorage.removeItem("access");

    // DISPATCH LOGOUT ACTIONS
    dispatch({ type: "LOGOUT" });
    window.location.href = "/login";
  }
  return { logout };
};

export default useLogout;
