export default function NotFound() {
  return (
    <div className="h-screen bg-[#0a0c0f] flex items-center justify-center">
      <div className="text-center ">
        <span className="ml-2 text-white text-7xl">⚠️</span>
        <div className="text-center max-w-7xl">
          <h2 className="text-white font-semibold text-lg ">Not found</h2>
          <p className="text-gray-400">
            The page you are looking for does not exist.
          </p>
          <span>
            <a
              href="https://algoostrategies.org"
              className="underline text-gray-300"
            >
              Go home
            </a>
          </span>
        </div>
      </div>
    </div>
  );
}
