import React, { useContext, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import SidebarComponent from "../../components/navigation/Sidebar";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/authContext";
import MiniWidget from "../../components/lib/mini-widget";
import { capitalize } from "@mui/material";
import CryptocurrencyConverter from "../../components/lib/Converter";
import { useState } from "react";
import DashboardFooter from "../../components/navigation/DashboardFooter";
import DepositYield from "../../components/modals/DepositYield";
import { AddComma } from "../../utils";
import client from "../../utils/client";

export const Yield = () => {
  const { user } = useContext(AuthContext);
  const [fixedYield, setFixedYield] = useState([]);
  const [userInvestments, setUserInvestments] = useState([]);
  const [amount, setAmount] = useState(0);
  const [quantity, setQuantity] = useState(0);
  const [open, setOpen] = useState(false);
  const [searchParams] = useSearchParams();

  const symbol = searchParams.get("symbol");
  const name = searchParams.get("name");

  async function getYields() {
    try {
      const { data } = await client({
        url: `/txns/fixed-yields`,
        method: "GET",
      });
      const yields = await data.data;
      if (yields) {
        const result = yields.filter(
          (singleYield) => singleYield.name === name
        );
        setFixedYield(result);
      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    getYields();
  });

  useEffect(() => {
    function getTotal() {
      let investmentAmount = 0;
      let investmentQuantity = 0;
      if (userInvestments.length > 0) {
        userInvestments.forEach((investment) => {
          investmentAmount =
            investmentAmount +
            parseFloat(investment.amount.replaceAll(",", ""));
          investmentQuantity =
            investmentQuantity +
            parseFloat(investment.quantity.replaceAll(",", ""));
        });
        setAmount(investmentAmount);
        setQuantity(investmentQuantity);
      }
    }

    if (fixedYield.length > 0) {
      const result = fixedYield[0].users.filter(
        (investment) => investment.user === user._id
      );
      setUserInvestments(result);
      getTotal();
    }
  }, [fixedYield, user._id, userInvestments]);

  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="relative p-4 lg:px-14 border border-gray-600 min-h-screen border-dashed rounded-lg dark:border-gray-700 mt-20">
          <div className="">
            <h1 className="text-lg md:text-xl font-medium text-gray-300 my-5">
              {capitalize(name)} ({fixedYield[0]?.symbol})
            </h1>
          </div>
          <div className="lg:grid grid-cols-3 gap-10">
            <div className="col-span-2 max-[1024px]:h-[450px] max-[1024px]:mb-16">
              <MiniWidget pair={symbol} />
            </div>
            <div>
              <div>
                <button
                  onClick={() => setOpen(!open)}
                  className="bg-[#b2ffce] rounded-lg w-full p-3 font-medium hover:bg-opacity-75 duration-500 mb-5"
                >
                  Deposit
                </button>
              </div>
              <div className="bg-gray-800 p-5 rounded-xl">
                <div className="">
                  <CryptocurrencyConverter currency={name} />
                </div>
                <div className="mt-5">
                  <h1 className="text-lg font-medium text-gray-300 mb-2">
                    My {capitalize(name)}
                  </h1>
                  <div className="space-y-3">
                    <div>
                      <p className="text-sm text-gray-400 font-medium">
                        Balance
                      </p>
                      <h1 className="text-lg text-gray-300">
                        ${AddComma(amount.toFixed(2))}
                      </h1>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 font-medium">
                        Quantity, {fixedYield.symbol}
                      </p>
                      <h1 className="text-lg text-gray-300">
                        {AddComma(quantity.toFixed(2))}
                      </h1>
                    </div>
                    <div>
                      <p className="text-sm text-gray-400 font-medium">
                        Current APY
                      </p>
                      <h1 className="text-lg text-gray-300">
                        {fixedYield.length > 0 && fixedYield[0].apy} %
                      </h1>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-9 left-[50%] text-left">
            <DashboardFooter />
          </div>
          {open && (
            <DepositYield userId={user._id} fixedYield={fixedYield[0]} />
          )}
        </div>
      </div>
    </div>
  );
};
