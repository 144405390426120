import React, { useState, useEffect } from "react";
import Confirmation from "./confirmation";
import Success from "./Success";
import ethereum from "../../assets/ethereum.png";
import usdt from "../../assets/usdt.png";
import usdtbsc from "../../assets/usdtbsc.png";
import WithdrawComponent from ".";
import useWithdraw from "../../hooks/useWithdraw";

const Withdraw = () => {
  const [state, setState] = useState({
    step: 1,
    assetChosen: "",
    networkChosen: "",
    amount: 0,
    walletAddress: "",
    network1: {
      name: "",
      logo: "",
    },
    network2: {
      name: "",
      logo: "",
    },
    network3: {
      name: "",
      logo: "",
    },
  });
  const { withdraw, loading, success, error } = useWithdraw();

  const prevStep = () => {
    const { step } = state;
    setState({ ...state, step: step - 1 });
  };
  const nextStep = () => {
    const { step } = state;
    setState({
      ...state,
      step: step + 1,
    });
  };
  const assetChange = (input) => (e) => {
    setState({ ...state, [input]: e.target.value });
  };
  useEffect(() => {
    if (
      state.assetChosen === "usdt" ||
      state.assetChosen === "usdc" ||
      state.assetChosen === "ethereum"
    ) {
      setState({
        ...state,
        network1: {
          name: "BSC",
          logo: usdtbsc,
        },
        network2: {
          name: "ERC20",
          logo: ethereum,
        },
        network3: {
          name: "Tron (TRX)",
          logo: usdt,
        },
      });
    } else {
      setState({
        ...state,
        network1: {
          name: "",
          logo: "",
        },
        network2: {
          name: "",
          logo: "",
        },
        network3: {
          name: "",
          logo: "",
        },
      });
    }
  }, [state.assetChosen, state]);

  async function withdrawFunds() {
    await withdraw(
      state.assetChosen,
      state.networkChosen,
      state.walletAddress,
      state.amount
    );
  }

  const { step } = state;
  const { assetChosen, networkChosen, amount, walletAddress } = state;
  const values = { networkChosen, assetChosen, amount, walletAddress };
  return (
    <>
      {step === 1 && (
        <WithdrawComponent
          nextStep={nextStep}
          handleChange={assetChange}
          values={values}
        />
      )}
      {step === 2 && (
        <Confirmation
          prevStep={prevStep}
          withdraw={withdrawFunds}
          loading={loading}
          success={success}
          error={error}
          values={values}
        />
      )}
      {step === 3 && <Success />}
    </>
  );
};

export default Withdraw;
