import React from "react";

const Confirmation = ({ prevStep, nextStep, values }) => {
  const previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  const nextPage = (e) => {
    e.preventDefault();
    nextStep();
  };
  return (
    <div>
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className="w-full p-4"
      >
        <div className="top-10 lg:top-20 max-w-xl max-lg:mx-auto md:h-auto">
          <div className="">
            <div className="p-4">
              <h3 className="text-xl font-semibold text-gray-300">Deposit</h3>
            </div>
            <div className="lg:px-6 py-5 text-gray-400">
              <div className="flex items-center space-x-2">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="w-4 h-4 text-yellow-300 mx-auto md:mx-0"
                  >
                    <path
                      fillRule="evenodd"
                      d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <h1 className="font-medium text-lg md:text-xl">
                  Select the correct cryptocurrency
                </h1>
              </div>
              <p className="mt-2 text-sm">
                Sending crypto to the wrong address or on the wrong network will
                result in lost funds. Please verify your deposit details.
              </p>
              <div className="mt-3 space-y-4">
                <div className="flex items-center justify-between">
                  <h1>Asset: </h1>
                  <p className="font-semibold uppercase">
                    {values.assetChosen}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <h1>Network: </h1>
                  <p className="font-semibold uppercase">
                    {values.networkChosen ? values.networkChosen : "none"}
                  </p>
                </div>
                <div className="flex items-center justify-between">
                  <h1>Amount: </h1>
                  <p className="font-semibold uppercase">${values.amount}</p>
                </div>
              </div>
            </div>
            <div className="flex items-center px-6 py-4 space-x-2 rounded-b">
              <button
                data-modal-hide="defaultModal"
                type="button"
                onClick={previous}
                className="text-gray-400 hover:text-gray-700 duration-500 border border-[#b2ffce] hover:bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
              >
                Go back
              </button>
              <button
                data-modal-hide="defaultModal"
                type="button"
                onClick={nextPage}
                className="bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
              >
                My details are correct
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Confirmation;
