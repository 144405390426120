import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Success = ({ handleChange, submit, load, success, error }) => {
  async function sendMail() {
    await submit();
  }

  return (
    <div>
      <div
        id="defaultModal"
        tabIndex="-1"
        aria-hidden="true"
        className="w-full p-4"
      >
        <div className="top-10 lg:top-20 max-w-xl max-lg:mx-auto md:h-auto">
          <div className="">
            <div className="p-4 rounded-t">
              <h3 className="text-xl font-semibold text-gray-300">Deposit</h3>
            </div>
            <div className="lg:px-6 py-5 text-gray-400">
              <div className="">
                <h1 className="font-medium text-lg md:text-xl">
                  Verify the transaction
                </h1>
              </div>
              <p className="mt-2 text-sm">
                Please enter the transaction ID below so that we can confirm
                your transaction. After confirmation, the funds will be
                deposited into your account wihin the next 24 hours.
              </p>
              <div className="mt-3 space-y-4">
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Transaction ID
                  </p>
                  <div>
                    <input
                      onChange={handleChange("txnId")}
                      type="text"
                      className="bg-[#0a0c0f] border text-gray-300 outline-none border-gray-400 mt-1 py-3 rounded-sm w-full"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center px-6 py-4 space-x-2 rounded-b">
              <button
                data-modal-hide="defaultModal"
                type="button"
                onClick={sendMail}
                className="bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
              >
                {load ? (
                  <CircularProgress color="inherit" size={25} />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        {success === true && (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="success">
              The transaction ID has been sent successfully
            </Alert>
          </Snackbar>
        )}
        {error && (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert severity="error">{error}</Alert>
          </Snackbar>
        )}
      </div>
    </div>
  );
};

export default Success;
