import { useState, useContext } from "react";
import { AuthContext } from "../context/authContext";
import client from "../utils/client";

const useWithdraw = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const { token } = useContext(AuthContext);

  const withdraw = async (asset, network, wallet, amount) => {
    setLoading(true);
    setError(null);
    client({
      url: `/pool/withdraw`,
      data: { asset, network, walletAddress: wallet, amount },
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          setLoading(false);
        }
        return res.data;
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.message);
        setLoading(false);
        setSuccess(false);
        return err;
      });
  };
  return { withdraw, loading, error, success };
};

export default useWithdraw;
