import React from "react";
import user from "../assets/user.png";
import presentation from "../assets/presentation.png";
import credit from "../assets/credit.png";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const NumbersTimeline = () => {
  return (
    <div>
      <ol className="items-center sm:grid grid-cols-3">
        <AnimationOnScroll animateIn="animate__zoomIn" duration={2}>
          {" "}
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <div className="z-10 flex items-center justify-center w-20 h-20 bg-[#33804f] rounded-full shrink-0 p-5">
                <img src={user} alt="" />
              </div>
              <div className="hidden sm:flex w-full bg-gray-700 h-0.5 hover:before:animate-wave before:underline before:underline-offset-8 before:decoration-wavy"></div>
            </div>
            <div className="mt-3 max-w-[80%] w-full">
              <h3 className="text-2xl tracking-wider font-semibold text-gray-900 dark:text-white">
                1500 users
              </h3>
              <p className="text-lg font-medium text-gray-500 dark:text-gray-400">
                Utilizing CeDeFi Strategies
              </p>
            </div>
          </li>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__zoomIn" duration={2}>
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <div className="z-10 flex items-center justify-center w-20 h-20 bg-[#33804f] rounded-full shrink-0 p-5">
                <img src={credit} alt="" />
              </div>
              <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
            </div>
            <div className="mt-3 max-w-[80%] w-full">
              <h3 className="text-2xl tracking-wider font-semibold text-gray-900 dark:text-white">
                $14,200,164
              </h3>
              <p className="text-lg font-medium text-gray-500 dark:text-gray-400">
                Total Deposits
              </p>
            </div>
          </li>
        </AnimationOnScroll>
        <AnimationOnScroll animateIn="animate__zoomIn" duration={2}>
          <li className="relative mb-6 sm:mb-0">
            <div className="flex items-center">
              <div className="z-10 flex items-center justify-center w-20 h-20 bg-[#33804f] rounded-full shrink-0 p-5">
                <img src={presentation} alt="" />
              </div>
              <div className="hidden sm:flex w-full bg-gray-700 h-0.5"></div>
            </div>
            <div className="mt-3 max-w-[80%] w-full">
              <h3 className="text-2xl tracking-wider font-semibold text-gray-900 dark:text-white">
                96 %+
              </h3>
              <p className="text-lg font-medium text-gray-500 dark:text-gray-400">
                Best monthly performance
              </p>
            </div>
          </li>
        </AnimationOnScroll>
      </ol>
    </div>
  );
};

export default NumbersTimeline;
