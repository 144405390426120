import React, { useContext } from "react";
import LeaderboardTable from "../../components/tables/Leaderboard";
import SidebarComponent from "../../components/navigation/Sidebar";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/authContext";

const Leaderboard = () => {
  const { user } = useContext(AuthContext);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 min-h-screen border-dashed rounded-lg dark:border-gray-700 mt-20">
          <div>
            <LeaderboardTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Leaderboard;
