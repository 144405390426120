import { jwtDecode } from "jwt-decode";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoute = () => {
  const token = localStorage.getItem("access");
  if (token) {
    const decodedToken = jwtDecode(token);
    if (decodedToken.exp * 1000 < Date.now()) {
      localStorage.removeItem("access");
      return <Navigate to="/login" />;
    } else {
      return <Outlet />;
    }
  } else {
    return <Navigate to="/login" />;
  }
};

export default ProtectedRoute;
