import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import client from "../../utils/client";
import Table from "./Table";

const LeaderboardTable = () => {
  const [pools, setPools] = useState([]);
  const navigate = useNavigate();

  async function getPools() {
    try {
      const { data } = await client({
        url: `/txns/pools`,
        method: "GET",
      });
      const sorted = data.data.sort(
        (a, b) => parseInt(b.weeklyReturn) - parseInt(a.weeklyReturn)
      );
      setPools(sorted);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getPools();
  }, []);

  return (
    <div>
      <div className="my-5">
        <h1 className="text-gray-300 font-medium text-3xl">Leaderboard</h1>
        <p className="text-gray-500 md:max-w-[75%] lg:max-w-[60%]">
          Explore our dynamic leaderboard to see the top-performing crypto
          trading strategies, updated in real-time for maximum transparency and
          insight.
        </p>
      </div>
      {pools.length > 0 ? (
        <Table
          columns={[
            {
              title: "Rank",
              render: (_, index) => (
                <h1 className="light-grey font-semibold text-xl">
                  {index + 1 >= 10 ? index + 1 : `0${index + 1}`}
                </h1>
              ),
            },
            {
              title: "Strategy Name",
              render: (data) => <h1 className="light-grey">{data.name}</h1>,
            },
            {
              title: "Managed",
              render: (data) => (
                <h1 className="light-grey">$ {data.valueManaged}</h1>
              ),
            },
            {
              title: "Weekly",
              render: (data) => (
                <h1 className="light-grey">{data.weeklyReturn} %</h1>
              ),
            },
            {
              title: "All time",
              render: (data) => (
                <h1 className="light-grey">{data.lifetimeReturn} %</h1>
              ),
            },

            {
              title: "",
              render: (data) => (
                <button
                  onClick={() => {
                    navigate(`/strategy/${data._id}`);
                  }}
                  className="py-2 px-4 max-[640px]:my-3 max-[640px]:w-full text-gray-700 bg-[#b2ffce] rounded-lg font-medium"
                >
                  View
                </button>
              ),
            },
          ]}
          data={pools}
        />
      ) : (
        <div>
          <p className="text-center text-gray-300">No available data</p>
        </div>
      )}
    </div>
  );
};

export default LeaderboardTable;
