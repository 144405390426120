import React, { useContext } from "react";
import SidebarComponent from "../../components/navigation/Sidebar";
import Loader from "../../components/Loader";
import { AuthContext } from "../../context/authContext";
import YieldsTable from "../../components/tables/Yields";

const FixedYield = () => {
  const { user } = useContext(AuthContext);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 min-h-screen border-dashed rounded-lg dark:border-gray-700 mt-20">
          <div>
            <h1 className="text-xl md:text-2xl font-medium text-gray-300 mb-2">
              Fixed Yield Catalog
            </h1>
          </div>
          <div>
            <YieldsTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FixedYield;
