import client from "./client";

export function AddComma(MyString) {
  const str = MyString.split(".");
  if (str[0].length >= 3) {
    str[0] = str[0].replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  if (str[1] && str[1].length >= 3) {
    str[1] = str[1].replace(/(\d{3})/g, "$1, ");
  }
  MyString = str.join(".");
  return MyString;
}

export async function getAccessToken(token) {
  try {
    const { data } = await client({
      url: "/auth/refresh",
      data: {
        refreshToken: token,
      },
      method: "POST",
    });
    return data;
  } catch (error) {
    console.log("access token generation error:", error);
  }
}
