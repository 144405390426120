import axios from "axios";
import { BASE_URL } from "./BaseUrl";
import { jwtExpired } from "./jwt";
import { getAccessToken } from ".";

const client = axios.create({
  //changed API url path
  //@ts-ignore
  baseURL: BASE_URL,
});

client.interceptors.request.use(async (req) => {
  // get auth header

  let accessAuthorization = "";
  if (req.headers) {
    accessAuthorization = req.headers["Authorization"];
  }

  // Only refresh if access authorization was passed in
  if (accessAuthorization && typeof accessAuthorization === "string") {
    // get token from authorization header
    const token = accessAuthorization.split(" ")[1];
    const expired = jwtExpired(token);
    const refreshToken = localStorage.getItem("refresh");

    if (typeof refreshToken !== "string") {
      alert("Please login before performing this action.");
    }

    if (expired && typeof refreshToken === "string") {
      // refresh token
      try {
        const res = await getAccessToken(refreshToken);

        // set tokens in local storage
        localStorage.setItem("access", res.auth.access);
        localStorage.setItem("refresh", res.auth.refresh);

        // update auth header
        if (req.headers) {
          req.headers["Authorization"] = `${res.auth.access}`;
        }
      } catch (err) {
        console.log(err);
        //   await deleteFromStorage("accessToken");
        //   await deleteFromStorage("refreshToken");
        //   await deleteFromStorage("accessTokenExpiry");
        Promise.reject(err);
      }
    }
  }
  return req;
});

export default client;
