import React from "react";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  Area,
  Tooltip,
  CartesianGrid,
} from "recharts";
import { format, parseISO, subDays } from "date-fns";

function normalizeValue(value, min, max) {
  return Math.min(Math.max(value, min), max);
}

const randomValues = [
  86.92, 55.86, 72.52, 55.56, 68.08, 43.6, 67.19, 89.56, 56.24, 83.81, 87.37,
  43.49, 39.79, 81.25, 80.96, 60.29, 67.1, 49.93, 78.92, 42.07, 70.03, 57.77,
  51.23, 62.34, 74.19, 69.32, 19.0, 68.42, 89.9, 79.05, 48.01,
];

const Chart = ({ monthly, yearly, boxHeight }) => {
  const data = [];
  for (let num = 30; num >= 0; num--) {
    console.log(num);
    data.push({
      date: subDays(new Date(), num).toISOString().substring(0, 10),
      performance: normalizeValue(randomValues[num], monthly, yearly),
    });
  }
  return (
    <ResponsiveContainer width="100%" height={boxHeight ? boxHeight : 200}>
      <AreaChart data={data}>
        <defs>
          <linearGradient id="color" x1="0" y1="0" x2="0" y2="1">
            <stop offset="0%" stopColor="#b2ffce" stopOpacity={0.4} />
            <stop offset="75%" stopColor="#b2ffce" stopOpacity={0.05} />
          </linearGradient>
        </defs>
        <Area dataKey="performance" stroke="#808080" fill="url(#color)" />
        <XAxis
          dataKey="date"
          axisLine={false}
          tickLine={false}
          tickFormatter={(str) => {
            const date = parseISO(str);
            if (date.getDate()) {
              return format(date, "MMM, d");
            }
            return "";
          }}
        />
        {/* <YAxis
          dataKey="performance"
          axisLine={false}
          tickLine={false}
          tickCount={8}
          tickFormatter={(number) => `${number.toFixed(2)}`}
        /> */}
        <Tooltip content={<CustomTooltip />} />
        <CartesianGrid opacity={0.1} vertical={false} />
      </AreaChart>
    </ResponsiveContainer>
  );
};

function CustomTooltip({ active, payload, label }) {
  if (active) {
    return (
      <div className="tooltip">
        <h4>{format(parseISO(label), "eeee, d, MMM, yyyy")}</h4>
        <p>Performance: {payload[0].value.toFixed(2)} %</p>
      </div>
    );
  }
  return null;
}

export default React.memo(Chart);
