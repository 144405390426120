import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function KYCModal() {
  const [open, setOpen] = useState(true);
  const router = useNavigate();
  return (
    open && (
      <div>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 bg-black/50 bg-opacity-50 z-20 w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full"
        >
          <div className="relative w-full h-full mx-auto top-[25%] max-w-xl md:h-auto">
            <div className="relative bg-[#181b20] z-[9999] opacity-100 rounded-lg shadow p-4">
              <div>
                <h2 className="text-xl text-gray-300 font-medium py-3 border-b border-gray-600">
                  Complete your KYC Verification{" "}
                  <span className="ml-2">⚠️</span>
                </h2>
                <p className="text-gray-400 mt-4">
                  Hello, we noticed that you haven't completed your KYC process,
                  to continue using our services without interruption, please
                  complete your KYC verification. It only takes a few minutes.
                </p>
                <div className="space-x-2">
                  <button
                    onClick={() => router("/kyc")}
                    className="max-md:w-full bg-[#b2ffce] py-2 px-4 text-sm rounded-md mt-5 font-medium"
                  >
                    Complete your KYC now
                  </button>
                  <button
                    onClick={() => {
                      setOpen(false);
                    }}
                    className="bg-red-300 px-4 py-2 text-sm max-md:w-full rounded-md font-medium"
                  >
                    Skip for now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}
