import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo.png";
import useLogout from "../../hooks/useLogout";
import client from "../../utils/client";

const SidebarComponent = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [realTimeUser, setRealTimeUser] = useState(user);
  const [show, setShow] = useState(false);
  const [drop, setDrop] = useState(false);
  const [dropOne, setDropOne] = useState(false);
  const [dropTwo, setDropTwo] = useState(false);
  const [dropThree, setDropThree] = useState(false);
  const { logout } = useLogout();

  function toggleShow() {
    setShow(!show);
  }
  useEffect(() => {
    client({ url: `/auth/echo?email=${user.email}`, method: "GET" })
      .then((res) => {
        setRealTimeUser(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [user, user.email]);
  return (
    <div>
      <nav className="fixed top-0 z-20 w-full bg-[#181b20] border-b-[0.5px] border-gray-600">
        <div className="px-3 py-5 lg:px-5 lg:pl-3">
          <div className="flex items-center justify-between">
            <div className="flex items-center justify-start">
              <button
                className="relative w-10 h-10 lg:hidden text-gray-500 rounded-sm focus:outline-none"
                onClick={() => setOpen(!open)}
              >
                <span className="sr-only">Open main menu</span>
                <div className="absolute block w-5 transform -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2">
                  <span
                    aria-hidden="true"
                    className={`block absolute h-0.5 w-5 bg-current transform transition duration-500 ease-in-out ${
                      open ? "rotate-45" : "-translate-y-1.5"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`block absolute  h-0.5 w-5 bg-current   transform transition duration-500 ease-in-out ${
                      open && "opacity-0"
                    }`}
                  ></span>
                  <span
                    aria-hidden="true"
                    className={`block absolute  h-0.5 w-5 bg-current transform  transition duration-500 ease-in-out ${
                      open ? "-rotate-45" : "translate-y-1.5"
                    }`}
                  ></span>
                </div>
              </button>
              <a
                href="https://algoostrategies.org"
                className="flex ml-2 lg:mr-24"
              >
                <img
                  src={logo}
                  className="h-8 mr-3"
                  alt="Algoo Strategies Logo"
                />
                <span className="self-center text-xl font-semibold sm:text-2xl whitespace-nowrap text-gray-300 ">
                  Algoo Strategies
                </span>
              </a>
            </div>
            <div className="flex items-center relative">
              <div className="flex items-center ml-3 space-x-5">
                <div>
                  <button
                    onClick={toggleShow}
                    type="button"
                    className="flex text-sm bg-gray-800 rounded-full"
                    aria-expanded="false"
                    data-dropdown-toggle="dropdown-user"
                  >
                    <span className="sr-only">Open user menu</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-8 h-8 text-gray-400 rounded-full"
                    >
                      <path
                        fillRule="evenodd"
                        d="M18.685 19.097A9.723 9.723 0 0021.75 12c0-5.385-4.365-9.75-9.75-9.75S2.25 6.615 2.25 12a9.723 9.723 0 003.065 7.097A9.716 9.716 0 0012 21.75a9.716 9.716 0 006.685-2.653zm-12.54-1.285A7.486 7.486 0 0112 15a7.486 7.486 0 015.855 2.812A8.224 8.224 0 0112 20.25a8.224 8.224 0 01-5.855-2.438zM15.75 9a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </button>
                </div>
                <div>
                  <Link to="/kyc">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className={`w-7 h-7 ${
                        realTimeUser.verified === true
                          ? "text-green-400"
                          : "text-red-400 animate-pulse"
                      }`}
                    >
                      <path
                        fillRule="evenodd"
                        d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </Link>
                  <div className="relative w-full">
                    <p className="text-xs text-gray-400 -mb-3 bottom-0 w-full">
                      KYC Status
                    </p>
                  </div>
                </div>
                <div
                  className={`z-50 ${
                    show ? "absolute top-8 right-0 duration-500" : "hidden"
                  } my-4 text-base list-none bg-[#0a0c0f] shadow-lg divide-y divide-gray-600 rounded dark:bg-gray-700 dark:divide-gray-600`}
                  id="dropdown-user"
                >
                  <div className="px-4 py-3" role="none">
                    <div className="flex items-center space-x-2">
                      <p
                        className="text-sm text-gray-300 capitalize"
                        role="none"
                      >
                        {user.name}
                      </p>
                      {user._type === "Base" && (
                        <svg
                          fill="none"
                          viewBox="0 0 32 32"
                          width="24"
                          height="24"
                        >
                          <rect
                            width="32"
                            height="32"
                            rx="16"
                            fill="#fff"
                            fill-opacity=".16"
                          ></rect>
                          <path
                            d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                            fill="#fff"
                            fill-opacity=".36"
                          ></path>
                        </svg>
                      )}
                      {user._type === "Bronze" && (
                        <svg
                          fill="none"
                          viewBox="0 0 32 32"
                          width="24"
                          height="24"
                        >
                          <rect
                            width="32"
                            height="32"
                            rx="16"
                            fill="#FFA858"
                          ></rect>
                          <path
                            d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                            fill="#0A0C0F"
                            opacity=".4"
                          ></path>
                        </svg>
                      )}
                      {user._type === "Silver" && (
                        <svg
                          fill="none"
                          viewBox="0 0 32 32"
                          width="24"
                          height="24"
                        >
                          <rect
                            width="32"
                            height="32"
                            rx="16"
                            fill="url(#paint0_radial_14470_83669-979578)"
                          ></rect>
                          <path
                            d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                            fill="#0A0C0F"
                            opacity=".4"
                          ></path>
                          <defs>
                            <radialGradient
                              id="paint0_radial_14470_83669-979578"
                              cx="0"
                              cy="0"
                              r="1"
                              gradientUnits="userSpaceOnUse"
                              gradientTransform="matrix(0 23 -23 0 16 9)"
                            >
                              <stop stop-color="#DAE1EF"></stop>
                              <stop offset="1" stop-color="#B6C2D9"></stop>
                            </radialGradient>
                          </defs>
                        </svg>
                      )}
                      {user._type === "Gold" && (
                        <svg
                          fill="none"
                          viewBox="0 0 32 32"
                          width="24"
                          height="24"
                        >
                          <rect
                            width="32"
                            height="32"
                            rx="16"
                            fill="url(#paint0_radial_14470_83671-389511)"
                          ></rect>
                          <path
                            d="M18.37 10.96c-.75-1.5-1.13-2.26-1.62-2.5a1.67 1.67 0 0 0-1.5 0c-.5.24-.87 1-1.62 2.5-.2.41-.31.62-.45.78-.18.2-.4.36-.66.46-.2.08-.43.1-.87.15-1.56.17-2.33.26-2.72.58-.5.4-.7 1.06-.55 1.68.12.48.7 1.03 1.86 2.1.36.34.54.51.66.7.19.3.27.65.25 1-.02.22-.1.45-.26.9-.52 1.5-.79 2.25-.69 2.7.16.72.76 1.26 1.49 1.32.47.04 1.18-.31 2.6-1.02l.52-.27c.43-.21.65-.32.88-.37.2-.03.41-.03.62 0 .23.05.45.16.88.37l.53.27c1.41.7 2.12 1.06 2.6 1.02.72-.06 1.32-.6 1.48-1.31.1-.46-.17-1.2-.7-2.7-.15-.46-.23-.7-.25-.92-.02-.34.06-.7.25-.99.12-.19.3-.36.66-.7 1.16-1.07 1.74-1.62 1.86-2.1a1.67 1.67 0 0 0-.55-1.68c-.39-.32-1.16-.4-2.72-.58a3.66 3.66 0 0 1-.87-.15c-.25-.1-.48-.26-.66-.46a3.75 3.75 0 0 1-.45-.78Z"
                            fill="#0A0C0F"
                            opacity=".4"
                          ></path>
                          <defs>
                            <radialGradient
                              id="paint0_radial_14470_83671-389511"
                              cx="0"
                              cy="0"
                              r="1"
                              gradientUnits="userSpaceOnUse"
                              gradientTransform="matrix(0 23 -23 0 16 9)"
                            >
                              <stop stop-color="#FFDB80"></stop>
                              <stop offset="1" stop-color="#FFC24D"></stop>
                            </radialGradient>
                          </defs>
                        </svg>
                      )}
                    </div>
                    <p
                      className="text-sm font-medium text-gray-400 truncate"
                      role="none"
                    >
                      {user.email}
                    </p>
                  </div>
                  <ul className="py-1" role="none">
                    <li>
                      <Link
                        to="/#tiers"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                        role="menuitem"
                      >
                        Upgrade
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/twofa"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                        role="menuitem"
                      >
                        Add 2-FA
                      </Link>
                    </li>
                    <li>
                      <a
                        href="https://blog.algoostrategies.org/"
                        target="_blank"
                        rel="noreferrer"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                        role="menuitem"
                      >
                        <div className="flex items-center justify-between">
                          <p>Blog</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-3 h-3 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                            />
                          </svg>
                        </div>
                      </a>
                    </li>
                    <li>
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href="https://wiki.algoostrategies.org/algoo-strategies-wiki/"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                        role="menuitem"
                      >
                        <div className="flex items-center justify-between">
                          <p>Wiki</p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-3 h-3 text-gray-400"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25"
                            />
                          </svg>
                        </div>
                      </a>
                    </li>
                    <li>
                      <Link
                        to="/profile"
                        role="menuitem"
                        className="block px-4 py-2 text-sm text-gray-400 hover:bg-gray-700 duration-500"
                      >
                        Profile
                      </Link>
                    </li>
                    <li>
                      <div
                        onClick={logout}
                        role="menuitem"
                        className="block px-4 py-2 text-sm cursor-pointer text-red-400 hover:bg-gray-700 duration-500"
                      >
                        Sign out
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      <aside
        id="logo-sidebar"
        className={`fixed top-0 left-0 z-10 w-64 min-h-screen h-full pt-28 transition-transform duration-500 ${
          open ? "translate-x-0" : "-translate-x-full"
        } bg-[#181b20] border-r-[0.5px] border-gray-600 lg:translate-x-0`}
        aria-label="Sidebar"
      >
        <div className="h-full px-3 pb-4 relative overflow-y-auto bg-[#181b20] dark:bg-gray-800">
          <ul className="space-y-2">
            <li>
              <Link to="/dashboard">
                <div className="flex items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 6a7.5 7.5 0 107.5 7.5h-7.5V6z"
                    />
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M13.5 10.5H21A7.5 7.5 0 0013.5 3v7.5z"
                    />
                  </svg>
                  <span className="ml-3">Dashboard</span>
                </div>
              </Link>
            </li>
            <li>
              <div
                onClick={() => {
                  setDrop(!drop);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"
                  />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Strategies
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${drop ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/dashboard/strategies"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition rounded-lg pl-11 group hover:bg-gray-700 duration-500"
                  >
                    CeDefi strategies
                  </Link>
                </li>
                <li className="flex items-center justify-between w-full p-2 text-base font-normal text-gray-400 transition rounded-lg pl-11 group hover:bg-gray-700 duration-500 group">
                  Fixed Yield
                  <div className="text-xs border border-red-500/50 text-red-500/50 px-2 py-1 rounded-full group-hover:border-red-800 group-hover:text-red-800">
                    coming soon
                  </div>
                </li>
                <li>
                  <Link
                    to="/leaderboard"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Leaderboard
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropOne(!dropOne);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>

                <span className="flex-1 ml-3 whitespace-nowrap">
                  Investments
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropOne ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/portfolio"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition rounded-lg pl-11 group hover:bg-gray-700 duration-500"
                  >
                    Portfolio
                  </Link>
                </li>
                <li>
                  <Link
                    to="/investment-history"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Investment History
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropTwo(!dropTwo);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                  />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">Deposits</span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropTwo ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/deposit-history"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Deposit History
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <div
                onClick={() => {
                  setDropThree(!dropThree);
                }}
                className="flex hover:cursor-pointer items-center p-2 text-base font-normal text-gray-400 rounded-lg duration-500 hover:bg-gray-700"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="flex-shrink-0 w-6 h-6 text-gray-500 transition duration-500 group-hover:text-gray-900"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
                  />
                </svg>
                <span className="flex-1 ml-3 whitespace-nowrap">
                  Withdrawals
                </span>
                <svg
                  sidebar-toggle-item="true"
                  className="w-6 h-6"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </div>
              <ul
                id="dropdown-example"
                className={`${dropThree ? "block" : "hidden"} py-2 space-y-2`}
              >
                <li>
                  <Link
                    to="/withdraw-history"
                    className="flex items-center w-full p-2 text-base font-normal text-gray-400 transition duration-500 rounded-lg pl-11 group hover:bg-gray-700"
                  >
                    Withdrawal History
                  </Link>
                </li>
              </ul>
            </li>
          </ul>
          <div className="absolute bottom-5 w-full px-4">
            <div className="mb-4">
              <a
                href="https://www.linkedin.com/company/algoostrategies/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="w-[30px]">
                  <svg
                    style={{ color: "rgb(5, 129, 245)" }}
                    xmlns="http://www.w3.org/2000/svg"
                    width="70%"
                    fill="currentColor"
                    className="bi bi-linkedin"
                    viewBox="0 0 16 16"
                  >
                    {" "}
                    <path
                      d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"
                      fill="#bbb"
                    ></path>{" "}
                  </svg>
                </div>
              </a>
            </div>
            <div className="flex items-center space-x-3">
              <Link
                to="/privacy"
                className="text-gray-400 font-medium hover:underline duration-500"
              >
                <h1>Privacy</h1>
              </Link>
              <Link
                to="/terms"
                className="text-gray-400 font-medium hover:underline duration-500"
              >
                <h1>Terms</h1>
              </Link>
              <a
                href="https://wiki.algoostrategies.org"
                target="_blank"
                rel="noreferrer"
                className="text-gray-400 font-medium hover:underline duration-500"
              >
                <h1>Wiki</h1>
              </a>
            </div>
            <div>
              <p className="font-medium text-xs text-center mt-4 text-gray-400">
                Copyright Algoo Strategies 2024.
              </p>
            </div>
          </div>
        </div>
      </aside>
    </div>
  );
};

export default SidebarComponent;
