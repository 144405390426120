import React, { useState } from "react";
import logo from "../../assets/logo.png";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { useEffect } from "react";
import client from "../../utils/client";

const DepositComponent = ({ nextStep, handleChange, values }) => {
  const [coins, setCoins] = useState([]);
  const [coinChosen, setCoinChosen] = useState(null);
  const nextPage = (e) => {
    e.preventDefault();
    nextStep();
  };

  async function getCoins() {
    try {
      const { data } = await client({ url: `/txns/coins`, method: "GET" });
      setCoins(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getCoins();
  }, []);

  return (
    coins && (
      <div>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="w-full p-4"
        >
          <div className="top-10 lg:top-20 max-w-xl max-lg:mx-auto md:h-auto">
            <div className="">
              <div className="lg:p-4 mb-6">
                <h3 className="text-xl font-semibold text-gray-300">
                  Deposit to:
                </h3>
              </div>
              <div className="lg:px-6 space-y-6">
                <div className="py-1">
                  <div className="flex items-center space-x-3">
                    <img
                      alt="logo"
                      className="w-[15%] lg:w-[70px] lg:h-[80px] p-2"
                      src={logo}
                    />
                    <h1 className="text-gray-300 font-medium text-lg">
                      Algoo Strategies
                    </h1>
                  </div>
                </div>
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Asset to deposit
                  </p>
                  <div>
                    <FormControl fullWidth>
                      <Select
                        className="bg-[#0a0c0f] border border-gray-400 mt-1"
                        onChange={handleChange("assetChosen")}
                      >
                        {coins.map((coin) => {
                          return (
                            <MenuItem
                              onClick={() => setCoinChosen(coin)}
                              value={coin.name}
                              className="flex items-center space-x-3 bg-[#0a0c0f]"
                            >
                              <div>
                                <img
                                  className="w-5 h-5"
                                  alt="asset_logo"
                                  src={coin.image}
                                />
                              </div>
                              <div>
                                <p className="font-bold text-gray-600">
                                  {coin.name}
                                </p>
                              </div>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Network
                  </p>
                  <div>
                    <FormControl fullWidth>
                      <Select
                        disabled={
                          values.networkChosen === "null" ? true : false
                        }
                        className="bg-[#0a0c0f] border border-gray-400 mt-1"
                        onChange={handleChange("networkChosen")}
                      >
                        {coinChosen &&
                          coinChosen.networks.map((network) => {
                            return (
                              <MenuItem
                                value={network}
                                className="flex items-center space-x-3 bg-[#0a0c0f]"
                              >
                                <div>
                                  <p className="font-bold text-gray-600">
                                    {network}
                                  </p>
                                </div>
                              </MenuItem>
                            );
                          })}
                      </Select>
                    </FormControl>
                  </div>
                </div>
                <div className="py-1">
                  <p className="text-base leading-relaxed text-gray-400">
                    Amount
                  </p>
                  <div>
                    <input
                      onChange={handleChange("amount")}
                      type="number"
                      className="bg-[#0a0c0f] border text-gray-300 outline-none border-gray-400 mt-1 py-3 rounded-sm w-full"
                    />
                  </div>
                </div>
              </div>
              <div>
                <p className="text-gray-300 my-1 px-6">
                  Minimum deposit amount is <span>$500</span>
                </p>
                <p className="text-gray-300 px-6 mb-3">
                  Please enter amount in dollars
                </p>
              </div>
              <div className="flex items-center p-6 space-x-2 rounded-b dark:border-gray-600">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={nextPage}
                  className="bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
                >
                  Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default DepositComponent;
