import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

const Confirmation = ({
  prevStep,
  withdraw,
  values,
  loading,
  success,
  error,
}) => {
  const [open, setOpen] = useState(true);
  const previous = (e) => {
    e.preventDefault();
    prevStep();
  };
  async function submit() {
    await withdraw();
  }
  return (
    open && (
      <div>
        <div
          id="defaultModal"
          tabIndex="-1"
          aria-hidden="true"
          className="fixed top-0 left-0 right-0 bg-gray-700 bg-opacity-50 z-[9999] w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-full"
        >
          <div className="relative w-full h-full mx-auto top-[25%] max-w-xl md:h-auto">
            <div className="relative bg-[#181b20] z-[9999] opacity-100 rounded-lg shadow">
              <div className="flex items-start justify-between p-4 border-b border-b-gray-400 rounded-t">
                <h3 className="text-xl font-semibold text-gray-300">
                  Withdraw
                </h3>
                <button
                  type="button"
                  onClick={() => {
                    setOpen(false);
                  }}
                  className="text-sm p-1.5 ml-auto inline-flex items-center text-gray-400"
                  data-modal-hide="defaultModal"
                >
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              <div className="px-6 py-5 text-gray-400">
                <div className="flex items-center space-x-2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      className="w-4 h-4 text-yellow-300 mx-auto md:mx-0"
                    >
                      <path
                        fillRule="evenodd"
                        d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
                        clipRule="evenodd"
                      />
                    </svg>
                  </div>
                  <h1 className="font-medium text-lg md:text-xl">
                    Review your details
                  </h1>
                </div>
                <p className="mt-2 text-sm">
                  Withdrawing crypto to the wrong address or on the wrong
                  network will result in lost funds. Please verify your
                  withdrawal details.
                </p>
                <div className="mt-3 space-y-4">
                  <div className="flex items-center justify-between">
                    <h1>Asset: </h1>
                    <p className="font-semibold uppercase">
                      {values.assetChosen}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <h1>Network: </h1>
                    <p className="font-semibold uppercase">
                      {values.networkChosen ? values.networkChosen : "none"}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <h1>Amount: </h1>
                    <p className="font-semibold uppercase">${values.amount}</p>
                  </div>
                  <div className="flex items-center justify-between">
                    <h1>Wallet Address: </h1>
                    <p className="font-semibold">{values.walletAddress}</p>
                  </div>
                </div>
              </div>
              <div className="flex items-center px-6 py-4 space-x-2 border-t border-gray-200 rounded-b">
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={previous}
                  className="text-gray-400 hover:text-gray-700 duration-500 border border-[#b2ffce] hover:bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
                >
                  Go back
                </button>
                <button
                  data-modal-hide="defaultModal"
                  type="button"
                  onClick={submit}
                  className="bg-[#b2ffce] rounded-md text-sm px-5 py-2 text-center"
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={25} />
                  ) : (
                    "Withdraw"
                  )}
                </button>
              </div>
            </div>
          </div>
          <div>
            {success === true && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="success">
                  Withdrawal request sent successfully.
                </Alert>
              </Snackbar>
            )}
            {error && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
          </div>
        </div>
      </div>
    )
  );
};

export default Confirmation;
