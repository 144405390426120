import { Fragment, useState, useEffect } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";
import client from "../utils/client";

function Icon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      strokeWidth={2}
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
    </svg>
  );
}

const Faq = () => {
  const [open, setOpen] = useState(1);
  const [faqs, setFaq] = useState([]);

  async function fetchFaq() {
    try {
      const { data } = await client({ url: "/faq", method: "GET" });
      setFaq(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    fetchFaq();
  }, []);
  const handleOpen = (value) => {
    setOpen(open === value ? 0 : value);
  };
  const customAnimation = {
    mount: { scale: 1 },
    unmount: { scale: 0.9 },
  };

  return (
    <Fragment>
      {faqs &&
        faqs.map((faq, index) => {
          return (
            <div key={index}>
              <Accordion
                open={open === index}
                animate={customAnimation}
                icon={<Icon id={index} open={open} />}
              >
                <AccordionHeader
                  onClick={() => handleOpen(index)}
                  className="text-gray-400 text-base font-normal p-4 border border-gray-400 rounded-md"
                >
                  <h1>{faq.title}</h1>
                </AccordionHeader>
                <AccordionBody>
                  <p className="text-gray-300 p-6 border text-base border-gray-400 rounded-md">
                    {faq.body}
                  </p>
                </AccordionBody>
              </Accordion>
            </div>
          );
        })}
    </Fragment>
  );
};

export default Faq;
