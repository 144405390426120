// TradingViewWidget.jsx

import React, { useEffect, useRef } from "react";

let tvScriptLoadingPromise;

export function RowWidget() {
  // useScript(
  //   "https://widgets.coingecko.com/div/coingecko-coin-price-chart-widget-div.js"
  // );
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-screener.js";
    script.async = true;
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      defaultColumn: "performance",
      screener_type: "crypto_mkt",
      displayCurrency: "USD",
      colorTheme: "dark",
      locale: "en",
      largeChartUrl: "https://algoostrategies.org/fixed-yield",
    });
    document.getElementById("myContainer").appendChild(script);
  }, []);

  return <div id="myContainer"></div>;
}

// <div
//   data-background-color=""
//   data-currency="usd"
//   data-coin-id={`${coin.toLowerCase()}`}
//   data-locale="en"
//   data-height="300"
//   data-width=""
//   class="coingecko-coin-price-chart-widget"
// ></div>

export default function MiniWidget({ pair }) {
  const onLoadScriptRef = useRef();
  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(
      () => onLoadScriptRef.current && onLoadScriptRef.current()
    );

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (
        document.getElementById("tradingview_4c088") &&
        "TradingView" in window
      ) {
        new window.TradingView.widget({
          width: "100%",
          height: "100%",
          symbol: pair,
          interval: "W",
          timezone: "Etc/UTC",
          theme: "dark",
          style: "3",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          container_id: "tradingview_4c088",
        });
      }
    }
  }, [pair]);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_4c088" className="rounded-2xl" />
      <div>
        <h2 className="text-gray-400 text-sm text-center mt-5">
          <span>{pair} chart</span>
        </h2>
      </div>
    </div>
  );
}
