import React, { useState } from "react";
import Livechat from "../components/LiveChat";
import Navbar from "../components/Navbar";
import Loader from "../components/Loader";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import client from "../utils/client";

export default function PortfolioBuilder() {
  const [details, setDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    investmentAttitude: "",
    maximumInvestment: "",
    portfolioProtection: "",
    riskPreference: "",
    investmentPeriod: "",
    financialPlan: "",
    investedInCrypto: "",
    defiManagementConsideration: "",
    investmentObjectives: "",
    nextExpenditure: "",
    investmentMotivation: "",
    userInvestments: "",
    otherInfo: "",
  });
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  async function submitAnswer(e) {
    e.preventDefault();
    setLoading(true);
    const result = await client({
      url: `/admin/portfolio-builder`,
      data: details,
      method: "POST",
    });
    if (result.status === 200) {
      setSuccess(true);
      setLoading(false);
    } else {
      setSuccess(false);
      setLoading(false);
    }
  }
  return (
    <div>
      <div className="bg-[#0a0c0f]">
        <div>
          <Livechat />
        </div>
        <div>
          <Loader />
        </div>
        <Navbar />
        <div className="w-[90%] md:w-2/3 lg:w-2/4 mx-auto mt-20 pb-20 bg-[#1e936a] p-5 md:p-10 rounded-md">
          <div className="border-b border-b-[#B3D37A] py-7 mb-8">
            <h1 className="font-medium text-2xl text-white">
              Algoo Strategies Portfolio Builder
            </h1>
          </div>
          <form onSubmit={submitAnswer} className="space-y-7">
            <div>
              <p className="mb-1 text-white">Name *</p>
              <div className="flex items-center justify-between w-[100%]">
                <input
                  onChange={(e) =>
                    setDetails({ ...details, firstName: e.target.value })
                  }
                  className="py-2 rounded-md outline-none bg-white px-3 w-[49%]"
                  placeholder="First Name"
                  required
                />
                <input
                  onChange={(e) =>
                    setDetails({ ...details, lastName: e.target.value })
                  }
                  className="py-2 rounded-md outline-none bg-white px-3 w-[49%]"
                  placeholder="Last Name"
                  required
                />
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">Email *</p>
              <div className="">
                <input
                  onChange={(e) =>
                    setDetails({ ...details, email: e.target.value })
                  }
                  className="py-2 rounded-md outline-none bg-white px-3 w-full"
                  placeholder="Email"
                  type="email"
                  required
                />
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                What is your investment attitude? *
              </p>
              <div>
                <select
                  required
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      investmentAttitude: e.target.value,
                    })
                  }
                >
                  <option>Please select an option</option>
                  <option>Very Conservative</option>
                  <option>Moderately Conservative</option>
                  <option>Moderate</option>
                  <option>Somewhat Aggressive</option>
                  <option>Aggressive</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                As a beginner, what's the maximum amount of money you are
                willing to invest? *
              </p>
              <div>
                <select
                  required
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      maximumInvestment: e.target.value,
                    })
                  }
                >
                  <option>Please select an option</option>
                  <option>$1000</option>
                  <option>$5,000</option>
                  <option>$10,000</option>
                  <option>$25,000</option>
                  <option>$50,000</option>
                  <option>$100,000+</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                Protecting my portfolio is more important to me than higher
                returns. How closely do you agree with this statement? *
              </p>
              <div>
                <select
                  required
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      portfolioProtection: e.target.value,
                    })
                  }
                >
                  <option>Please select an option</option>
                  <option>Strongly Agree</option>
                  <option>Agree</option>
                  <option>Neutral</option>
                  <option>Disagree</option>
                  <option>Strongly Disagree</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                Taking your risk preference into consideration, which of these
                statements do you agree with the most? *{" "}
              </p>
              <div>
                <select
                  required
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({ ...details, riskPreference: e.target.value })
                  }
                >
                  <option>Please select an option</option>
                  <option>
                    I am willing to bear the consequences of a loss to maximize
                    my returns
                  </option>
                  <option>
                    I am concerned about losses along with returns
                  </option>
                  <option>
                    To completely avoid losses is of more interest to me
                  </option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                What is your desired time frame for your investments? *{" "}
              </p>
              <div>
                <select
                  required
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({ ...details, investmentPeriod: e.target.value })
                  }
                >
                  <option>Please select an option</option>
                  <option>Short-term (3 months - 1 year) Agree</option>
                  <option>Medium-term (1 - 3years)</option>
                  <option>Long-term (More than 3years)</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                What is your long-term financial plan and how does
                cryptocurrency fit into that plan? *{" "}
              </p>
              <div>
                <select
                  required
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({ ...details, financialPlan: e.target.value })
                  }
                >
                  <option>Please select an option</option>
                  <option>
                    Including cryptocurrency as a small part of a diversified
                    investment portfolio
                  </option>
                  <option>
                    Using cryptocurrency as a primary means of saving and
                    growing wealth
                  </option>
                  <option>
                    Using cryptocurrency to supplement my income through trading
                    or other activities
                  </option>
                  <option>
                    Using cryptocurrency as a hedge against traditional market
                    volatility or economic instability
                  </option>
                  <option>Other(please specify)</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                Have you previously invested in cryptocurrency? If so, what was
                your experience? *
              </p>
              <div>
                <select
                  required
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({ ...details, investedInCrypto: e.target.value })
                  }
                >
                  <option>Please select an option</option>
                  <option>
                    I have never invested in cryptocurrency before
                  </option>
                  <option>
                    I have invested in cryptocurrency in the past and it was a
                    positive experience
                  </option>
                  <option>
                    I have invested in cryptocurrency in the past and it was a
                    negative experience
                  </option>
                  <option>
                    I have invested in cryptocurrency in the past and it was
                    both positive and negative
                  </option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                Have you considered using a DeFi asset management platform to
                invest in cryptocurrency?
              </p>
              <div>
                <select
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      defiManagementConsideration: e.target.value,
                    })
                  }
                >
                  <option>Please select an option</option>
                  <option>
                    Yes, I have considered using a DeFi asset management
                    platform
                  </option>
                  <option>
                    No, I have not considered using a DeFi asset management
                    platform
                  </option>
                  <option>
                    I am not familiar with DeFi asset management platforms
                  </option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                Which of the following statements best describes your investment
                objectives?
              </p>
              <div>
                <select
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      investmentObjectives: e.target.value,
                    })
                  }
                >
                  <option>Please select an option</option>
                  <option>I feel comfortable with stable investments</option>
                  <option>
                    I am willing to withstand some fluctuations in my investment
                  </option>
                  <option>I am seeking substantial investment returns</option>
                  <option>
                    I am seeking potentially high investment returns
                  </option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                What do you expect to be your next major expenditure?
              </p>
              <div>
                <select
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({ ...details, nextExpenditure: e.target.value })
                  }
                >
                  <option>Please select an option</option>
                  <option>Buying a house</option>
                  <option>Capitalizing a new business venture</option>
                  <option>Paying college tuition</option>
                  <option>Providing for my retirement</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                What motivated you to start considering investing in
                cryptocurrency?
              </p>
              <div>
                <select
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({
                      ...details,
                      investmentMotivation: e.target.value,
                    })
                  }
                >
                  <option>Please select an option</option>
                  <option>I want to diversify my investment portfolio</option>
                  <option>
                    I heard about the potential for high returns on
                    cryptocurrency investments
                  </option>
                  <option>
                    I have been advised by a financial professional to consider
                    investing in cryptocurrency
                  </option>
                  <option>
                    I am interested in the technology behind cryptocurrency
                  </option>
                  <option>Other (please specify)</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                Select the investments you currently have
              </p>
              <div>
                <select
                  className="rounded-lg shadow-lg w-full"
                  onChange={(e) =>
                    setDetails({ ...details, userInvestments: e.target.value })
                  }
                >
                  <option>Please select an option</option>
                  <option>Stocks</option>
                  <option>Cryptocurrency</option>
                  <option>Real Estate</option>
                  <option>Bonds</option>
                  <option>I do not currently have any investment</option>
                  <option>Other (please specify)</option>
                </select>
              </div>
            </div>
            <div>
              <p className="mb-1 text-white">
                Is there anything else that you think we should know in order to
                recommend a customized cryptocurrency portfolio allocation for
                you?
              </p>
              <div>
                <textarea
                  onChange={(e) =>
                    setDetails({ ...details, otherInfo: e.target.value })
                  }
                  rows={6}
                  className="w-full rounded-md shadow-md"
                ></textarea>
              </div>
              <p className="mt-1 font-medium text-sm text-[#566638]">
                We want to ensure that we have all the necessary information to
                make informed recommendations
              </p>
            </div>
            <div className="flex items-center justify-center border-t border-t-[#B3D37A] pt-9">
              <button
                type="submit"
                className="px-10 text-white bg-[#566638] rounded-md mx-auto text-sm py-3"
              >
                {loading ? (
                  <CircularProgress color="inherit" size="25px" />
                ) : (
                  "Submit"
                )}
              </button>
            </div>
          </form>
          <div>
            {success === true && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="success">
                  We have received your submission and will be in touch with you
                  soon!
                </Alert>
              </Snackbar>
            )}
            {success === false && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="error">
                  An error occured, please try again later
                </Alert>
              </Snackbar>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
