import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import client from "../utils/client";

const useLogin = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const { dispatch } = useAuthContext();

  const login = async (email, password) => {
    setLoading(true);
    setError(null);
    client({
      url: `/auth/signin`,
      data: {
        email: email.trim(),
        password: password.trim(),
      },
      method: "POST",
    })
      .then((res) => {
        if (res.status === 200) {
          setSuccess(true);
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          localStorage.setItem("access", res.data.data.access);
          localStorage.setItem("refresh", res.data.data.refresh);
          dispatch({ type: "LOGIN", payload: res.data.data.user });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        setError(err.response.data.message);
        setSuccess(false);
      });
  };
  return { login, loading, error, success };
};

export default useLogin;
