import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/authContext";
import SidebarComponent from "../../components/navigation/Sidebar";
import Loader from "../../components/Loader";
import { BASE_URL } from "../../utils/BaseUrl";
import client from "../../utils/client";

const Profile = () => {
  const { user, token } = useContext(AuthContext);
  const [copied, setCopied] = useState(false);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [referrals, setReferrals] = useState(0);

  async function getReferrals() {
    client({
      url: `/auth/referrals`,
      method: "GET",
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => {
        setReferrals(res.data.referrals.length);
      })
      .catch((err) => {
        console.log("Error: ", err);
      });
  }

  function copyWallet() {
    navigator.clipboard.writeText(
      `https://algoostrategies.org/signup?ref=${user.referralCode}`
    );
    setCopied(true);
  }

  async function updateDetails(userName, userPhone, userState, userCountry) {
    await client({
      method: "PUT",
      url: `${BASE_URL}/auth/me/update`,
      data: {
        name: userName,
        country: userCountry,
        state: userState,
        phone: userPhone,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  useEffect(() => {
    getReferrals();
  });
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div>
            <h1 className="text-gray-400 text-xl font-medium">Settings</h1>
          </div>
          <div className="md:grid grid-cols-2 gap-5">
            <form className="md:grid grid-cols-2 gap-10 mt-10">
              <div>
                <label htmlFor="name" className="text-gray-300 text-sm">
                  Name
                </label>
                <input
                  name="name"
                  defaultValue={user.name}
                  onChange={(e) => setName(e.target.value)}
                  className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                />
              </div>
              <div>
                <label htmlFor="email" className="text-gray-300 text-sm">
                  Email
                </label>
                <input
                  name="email"
                  defaultValue={user.email}
                  readOnly
                  className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                />
              </div>
              <div>
                <label htmlFor="type" className="text-gray-300 text-sm">
                  Tier
                </label>
                <input
                  name="type"
                  defaultValue={user._type}
                  readOnly
                  className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                />
              </div>
              <div>
                <label htmlFor="phone" className="text-gray-300 text-sm">
                  Phone
                </label>
                <input
                  name="phone"
                  defaultValue={user.phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                />
              </div>
              <div>
                <label htmlFor="country" className="text-gray-300 text-sm">
                  Country
                </label>
                <input
                  name="country"
                  defaultValue={user.country}
                  onChange={(e) => setCountry(e.target.value)}
                  className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                />
              </div>
              <div>
                <label htmlFor="state" className="text-gray-300 text-sm">
                  State
                </label>
                <input
                  name="state"
                  defaultValue={user.state}
                  onChange={(e) => setState(e.target.value)}
                  className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                />
              </div>
              <div>
                <button
                  onClick={(e) => {
                    e.preventDefault();
                    updateDetails(name, phone, state, country);
                  }}
                  type="submit"
                  className="md:w-1/2 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium max-md:mt-8"
                >
                  Submit
                </button>
              </div>
            </form>
            <div>
              <h1 className="text-gray-300 font-medium text-lg mb-4 max-md:mt-5">
                Affiliate Program
              </h1>
              <div className="bg-[#181b20] p-2 rounded-xl relative">
                <div className="bg-gradient-to-r from-[#0a0c0f] via-green-500 to-[#181b20] p-5 border-2 border-opacity-70 border-gray-400 rounded-xl">
                  <div>
                    <h1 className="text-gray-200 font-medium text-xl tracking-wide mb-2 mt-5">
                      Share the link and{" "}
                      <span className="bg-white text-gray-800 font-bold p-1 rounded-lg mx-1">
                        GET 10%
                      </span>{" "}
                      of all referral rewards in USDC
                    </h1>
                  </div>
                  <div className="flex bg-gray-200 w-full py-3 px-5 text-gray-700 mt-5 rounded-xl items-center justify-between">
                    <input
                      name="ref"
                      defaultValue={`https://algoostrategies.org/signup?ref=${user.referralCode}`}
                      readOnly
                      className="w-full outline-none font-medium bg-transparent"
                    />
                    <div className="relative">
                      {copied && (
                        <div className="absolute bg-gray-500 text-gray-900 -top-8 -left-8 rounded-md text-xs py-1 px-2">
                          Copied!
                        </div>
                      )}
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        onClick={copyWallet}
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6 text-gray-900 hover:cursor-pointer"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                        />
                      </svg>
                    </div>
                  </div>
                  <ul class="max-md:space-y-5 md:flex md:space-x-14 items-center justify-between mt-8 relative">
                    <li class="">
                      <svg
                        class=""
                        fill="none"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <rect
                          width="36"
                          height="36"
                          fill="#fff"
                          fill-opacity=".24"
                          rx="18"
                        ></rect>
                        <path
                          fill="#fff"
                          fill-rule="evenodd"
                          d="M20.25 9.75a.75.75 0 0 0 0 1.5 2.25 2.25 0 0 1 0 4.5.75.75 0 0 0 0 1.5 3.75 3.75 0 1 0 0-7.5Zm1.5 9a.75.75 0 0 0 0 1.5h.9a2.85 2.85 0 0 1 2.85 2.85c0 .91-.74 1.65-1.65 1.65h-2.1a.75.75 0 0 0 0 1.5h2.1A3.15 3.15 0 0 0 27 23.1c0-2.4-1.95-4.35-4.35-4.35h-.9Zm-10.5-5.25a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Zm2.1 5.25A4.35 4.35 0 0 0 9 23.1a3.15 3.15 0 0 0 3.15 3.15h5.7A3.15 3.15 0 0 0 21 23.1c0-2.4-1.95-4.35-4.35-4.35h-3.3Z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>{" "}
                      <p className="text-white mt-2 font-medium">
                        Invite friends to Algoo Strategies via personal link
                      </p>
                    </li>{" "}
                    <li class="">
                      <svg
                        class=""
                        fill="none"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <rect
                          width="36"
                          height="36"
                          fill="#fff"
                          fill-opacity=".24"
                          rx="18"
                        ></rect>
                        <path
                          fill="#fff"
                          fill-rule="evenodd"
                          d="M16.3 11.25c1.22 0 1.68 0 2.1.13a3.25 3.25 0 0 1 1.84 1.37H12a.75.75 0 0 1 0-1.5h4.3ZM9.75 12c0-1.24 1-2.25 2.25-2.25h4.41c1.07 0 1.75 0 2.4.18.56.16 1.1.43 1.56.79.53.4.94.95 1.58 1.8l.07.09.17.22c.4.05.78.14 1.13.28a4.75 4.75 0 0 1 2.8 3.39h-1.37a3 3 0 0 0 0 6h1.37c-.05.29-.12.56-.23.82a4.75 4.75 0 0 1-2.57 2.57c-.48.2-.98.28-1.58.32-.58.04-1.3.04-2.21.04H16.87c-1.1 0-1.96 0-2.66-.06a4.88 4.88 0 0 1-1.87-.46 4.75 4.75 0 0 1-2.07-2.07 4.88 4.88 0 0 1-.46-1.87c-.06-.7-.06-1.56-.06-2.66V12Zm16.5 9v-3h-1.5a1.5 1.5 0 0 0 0 3h1.5Z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>{" "}
                      <p className="text-white mt-2 font-medium">
                        Wait for their deposits of at least $500 in any asset
                      </p>
                    </li>{" "}
                    <li class="">
                      <svg
                        class=""
                        fill="none"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                      >
                        <rect
                          width="36"
                          height="36"
                          fill="#fff"
                          fill-opacity=".24"
                          rx="18"
                        ></rect>
                        <path
                          fill="#fff"
                          fill-rule="evenodd"
                          d="M21.75 12.38c0-.63-.5-1.13-1.13-1.13h-.06c-.52 0-.98.34-1.13.84l-.42 1.41h1.61c.63 0 1.13-.5 1.13-1.13ZM23 13.5a2.63 2.63 0 0 0-2.37-3.75h-.07c-1.17 0-2.21.77-2.56 1.89a2.69 2.69 0 0 0-2.56-1.89h-.06A2.63 2.63 0 0 0 13 13.5h-1.38a1.88 1.88 0 0 0 0 3.75h12.76a1.88 1.88 0 0 0 0-3.75H23Zm-7.63 0H17l-.42-1.4c-.15-.5-.61-.85-1.13-.85h-.06a1.13 1.13 0 0 0 0 2.25ZM13.9 18H22.11c.31 0 .59 0 .82.02a1.95 1.95 0 0 1 1.6 1.05c.14.25.18.5.2.75.02.24.02.52.02.83v.06c0 .82 0 1.48-.04 2.02a3.86 3.86 0 0 1-.37 1.47c-.36.7-.93 1.28-1.64 1.64-.44.23-.92.32-1.47.37-.53.04-1.19.04-2 .04H16.77c-.81 0-1.47 0-2-.04a3.84 3.84 0 0 1-1.47-.37 3.75 3.75 0 0 1-1.64-1.64 3.86 3.86 0 0 1-.37-1.47c-.04-.54-.04-1.2-.04-2.02v-.06c0-.31 0-.6.02-.83a1.95 1.95 0 0 1 1.05-1.61c.25-.13.5-.17.75-.2l.82-.01Z"
                          clip-rule="evenodd"
                        ></path>
                      </svg>{" "}
                      <p className="text-white mt-2 font-medium">
                        Receive 10% from friends rewards
                      </p>
                    </li>
                  </ul>
                </div>
                <div className="md:flex items-center justify-between px-5 py-9">
                  <div>
                    <h1 className="text-gray-300 font-medium text-lg">5,922</h1>
                    <p className="text-gray-400">
                      members already have their rewards
                    </p>
                  </div>
                  <div>
                    <h1 className="text-gray-300 font-medium text-lg">
                      $3,042
                    </h1>
                    <p className="text-gray-400">biggest affiliate reward</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-[#181b20] px-5 py-9 my-14 rounded-2xl">
            <h1 className="text-gray-300 text-xl font-semibold mb-3">
              Your Referral Stats
            </h1>
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-gray-400 font-medium mb-2">
                  Your Earnings, USDC
                </h1>
                <h1 className="text-gray-400 font-medium mb-2">$0</h1>
              </div>
              <div>
                <h1 className="text-gray-400 font-medium mb-2">
                  Registered Users
                </h1>
                <h1 className="text-gray-400 font-medium mb-2">{referrals}</h1>
              </div>
              <div>
                <h1 className="text-gray-400 font-medium mb-2">
                  Referral Funds, USDC
                </h1>
                <h1 className="text-gray-400 font-medium mb-2">$0</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
