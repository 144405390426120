import { useState } from "react";
import { useAuthContext } from "./useAuthContext";
import client from "../utils/client";

const useSignup = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const { dispatch } = useAuthContext();

  const signup = async (url, name, email, password) => {
    setLoading(true);
    setError(null);
    client({
      url,
      data: {
        name,
        email,
        password: password.trim(),
      },
      method: "POST",
    })
      .then((res) => {
        if (res.status === 201) {
          setSuccess(true);
          localStorage.setItem("user", JSON.stringify(res.data.data.user));
          localStorage.setItem("access", res.data.data.auth.access);
          localStorage.setItem("refresh", res.data.data.auth.refresh);
          dispatch({ type: "LOGIN", payload: res.data.data.user });
          setLoading(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(err.response.data.message);
        setLoading(false);
        setSuccess(false);
      });
  };
  return { signup, loading, error, success };
};

export default useSignup;
