import React, { useContext } from "react";
import SidebarComponent from "../../components/navigation/Sidebar";
import WithdrawHistoryComponent from "../../components/tables/WithdrawHistory";
import { AuthContext } from "../../context/authContext";
import Loader from "../../components/Loader";

const WithdrawHistory = () => {
  const { user } = useContext(AuthContext);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 min-h-screen border-dashed rounded-lg dark:border-gray-700 mt-20">
          <h1 className="text-gray-300 font-medium text-3xl my-5">
            Withdrawal History
          </h1>
          <div>
            <WithdrawHistoryComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawHistory;
