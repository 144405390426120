import React, { useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { useForm } from "@formcarry/react";

const Form = () => {
  const [result, setResult] = useState("");
  const [success, setSuccess] = useState(null);
  const { state, submit } = useForm({
    id: "j0miRjvQzR",
  });

  // Success message
  if (state.submitted) {
    setResult("Thank you! We received your submission.");
    setSuccess(true);
    setTimeout(() => {
      window.location.reload(true);
    }, 5000);
  }
  if (state.submitting) {
    setResult("Sending....");
  }

  return (
    <div className="bg-[#181b20] p-6 rounded-md shadow-md md:w-full">
      <form onSubmit={submit} className="space-y-8">
        <div>
          <label
            htmlFor="name"
            className="mb-2 text-lg font-medium text-gray-400 block "
          >
            Name (required)
          </label>
          <input
            className="w-full md:w-[80%] bg-gray-200 border-2 border-gray-700 rounded-md px-4 py-2 outline-none"
            type="text"
            name="name"
            required
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="mb-2 text-lg font-medium text-gray-400 block "
          >
            Email (required)
          </label>
          <input
            className="w-full md:w-[80%] bg-gray-200 border-2 border-gray-700 rounded-md px-4 py-2 outline-none"
            type="email"
            name="email"
            required
          />
        </div>
        <div>
          <label
            htmlFor="message"
            className="mb-2 text-lg font-medium text-gray-400 block "
          >
            Message (required)
          </label>
          <textarea
            className="w-full md:w-[80%] bg-gray-200 border-2 border-gray-700 rounded-md px-4 py-2 outline-none"
            name="message"
            required
          />
        </div>
        <div>
          <button
            className="bg-[#b2ffce] text-gray-700 w-1/4 py-2 rounded-md text-center font-medium hover:cursor-pointer"
            type="submit"
          >
            {result === "Sending...." ? (
              <CircularProgress color="inherit" size="25px" />
            ) : (
              "Submit"
            )}
          </button>
        </div>
      </form>
      <div className="mt-10">
        {success === true && (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert className="mt-5" severity="success">
              {result}
            </Alert>
          </Snackbar>
        )}
        {success === false && (
          <Snackbar open={true} autoHideDuration={5000}>
            <Alert className="mt-5" severity="error">
              {result}
            </Alert>
          </Snackbar>
        )}
      </div>
    </div>
  );
};

export default Form;
