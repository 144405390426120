import { jwtDecode } from "jwt-decode";

export const jwtExpired = (token) => {
  if (token === undefined || !token) {
    return true;
  }
  const decodedToken = jwtDecode(token);

  const expirationTime = decodedToken.exp;
  if (Date.now() >= expirationTime * 1000) {
    return true;
  } else {
    return false;
  }
};
