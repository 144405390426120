import React from "react";
import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { AddComma } from "../../utils";
import CircularProgress from "@mui/material/CircularProgress";
import client from "../../utils/client";

const DepositYield = ({ userId, fixedYield }) => {
  const [amount, setAmount] = useState("");
  const [quantity, setQuantity] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);

  useEffect(() => {
    axios
      .get(
        `https://api.coingecko.com/api/v3/simple/price?ids=${fixedYield.name}&vs_currencies=usd`
      )
      .then(async (response) => {
        const rate = response.data[fixedYield.name].usd;
        const convertedAmount = rate * parseFloat(quantity);
        setAmount(AddComma(convertedAmount.toFixed(2).toString()));
      })
      .catch((error) => {
        console.log(error);
      });
  }, [quantity, fixedYield.name]);

  async function depositYield() {
    setLoading(true);

    client({
      url: "/txns/fixed-yield/invest",
      method: "POST",
      data: {
        yieldId: fixedYield._id,
        userId,
        amount,
        quantity,
      },
    })
      .then((res) => {
        alert(res.data.message);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data.message);
        setLoading(false);
      });
  }
  return (
    <div className={`${open ? "block" : "hidden"}`}>
      <div>
        <div className="justify-center items-center flex overflow-x-hidden px-4 max-w-md mx-auto overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative w-full my-6 mx-auto">
            {/*content*/}
            <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-gray-900 outline-none focus:outline-none text-gray-300">
              {/*header*/}
              <div className="flex items-center justify-between p-5 border-b border-solid border-gray-800 rounded-t">
                <h3 className="text-lg lg:text-xl font-medium">
                  Deposit - Fixed Yield ({fixedYield.symbol})
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setOpen(!open)}
                >
                  <span className="bg-transparent h-6 w-6 text-2xl block outline-none focus:outline-none">
                    x
                  </span>
                </button>
              </div>
              {/*body*/}
              <div className="relative p-6 flex-auto">
                <form className="space-y-3">
                  <div>
                    <h2 className="mb-1 text-sm">Quantity</h2>
                    <input
                      required
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                      className="w-full py-2 px-4 outline-none border border-gray-800 rounded-lg text-gray-900"
                      placeholder="Please enter a yield quantity"
                    />
                  </div>
                  <div>
                    <h2 className="mb-1 text-sm">Amount</h2>
                    <input
                      value={amount}
                      readOnly
                      className="w-full py-2 px-4 outline-none border border-gray-800 text-gray-900 rounded-lg"
                    />
                  </div>
                </form>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-end p-6 border-t border-solid border-gray-800 rounded-b">
                <button
                  className="bg-[#b2ffce] text-gray-800 font-medium text-sm px-6 py-3 rounded-md outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={depositYield}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size="25px" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </div>
    </div>
  );
};

export default DepositYield;
