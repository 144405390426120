import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../context/authContext";
import client from "../../utils/client";
import Table from "./Table";
import truncateText from "../../utils/truncate-text";

const DepositHistoryComponent = () => {
  const [deposits, setDeposits] = useState([]);
  const { user } = useContext(AuthContext);

  async function getDeposits() {
    try {
      const { data } = await client({
        url: `/txns/deposit?id=${user._id}`,
        method: "GET",
      });
      setDeposits(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getDeposits();
  }, []);

  return (
    <div>
      {deposits && deposits.length > 0 ? (
        <Table
          columns={[
            {
              title: "Asset",
              render: (data) => (
                <h1 className="light-grey">{data.asset.toUpperCase()}</h1>
              ),
            },
            {
              title: "Network",
              render: (data) => <h1 className="light-grey">{data.network}</h1>,
            },
            {
              title: "Amount",
              render: (data) => <h1 className="light-grey">{data.amount}</h1>,
            },
            {
              title: "Transaction ID",
              render: (data) => (
                <h1 className="light-grey">
                  {truncateText(data.txnId, 10, true)}
                </h1>
              ),
            },
            {
              title: "Date",
              render: (data) => (
                <h1 className="light-grey">
                  {truncateText(data.createdAt, 10)}
                </h1>
              ),
            },
          ]}
          data={deposits}
        />
      ) : (
        <div>
          <p className="text-center text-gray-300">No available data</p>
        </div>
      )}
    </div>
  );
};

export default DepositHistoryComponent;
