import React, { useContext } from "react";
import SidebarComponent from "../../components/navigation/Sidebar";
import { AuthContext } from "../../context/authContext";
import Loader from "../../components/Loader";
import TwoFactorAuthentication from "../../components/lib/Secret";

const TwoFactorAuth = () => {
  const { user, token } = useContext(AuthContext);
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div className="mb-6">
            <h1 className="text-2xl md:text-3xl text-gray-300 font-medium">
              Add Two Factor authentication
            </h1>
            <p className="text-gray-400 text-sm">
              Secure your account by adding double-factor authentication.
              <br /> This code can be used when you forget your passwords and
              adiitionally verify your account.
            </p>
          </div>
          <div>
            <div>
              <TwoFactorAuthentication authToken={token} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
