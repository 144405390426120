import React, { useContext } from "react";
import InvestmentTable from "../../components/tables/InvestmentTable";
import SidebarComponent from "../../components/navigation/Sidebar";
import { AuthContext } from "../../context/authContext";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

const Investment = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          <div className="flex items-center justify-between mb-6">
            <h1 className="text-2xl md:text-3xl text-gray-300 font-medium">
              Portfolio
            </h1>
            <button
              onClick={() => navigate("/dashboard/strategies")}
              className="bg-[#b2ffce] rounded-md px-4 py-1 outline-none text-gray-700 font-medium text-sm"
            >
              View Strategies
            </button>
          </div>
          <div>
            <InvestmentTable />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Investment;
