import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";
import Loader from "../components/Loader";
import useLogin from "../hooks/useLogin";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import client from "../utils/client";

const TwoFALogin = () => {
  const [email, setEmail] = useState("");
  const [token, setToken] = useState("");
  const { loading, error, success } = useLogin();
  const navigate = useNavigate();
  async function loginUser(e) {
    e.preventDefault();
    client({
      url: `/auth/validate-twofa`,
      data: { email, token },
      method: "POST",
    }).catch((err) => {
      console.log("Err: ", err);
    });
  }
  useEffect(() => {
    success &&
      setTimeout(() => {
        navigate("/dashboard");
      }, 5000);
  }, [success, navigate]);

  return (
    <div>
      <div className="bg-[#0a0c0f] min-h-screen w-full px-8 pt-36 md:pt-0 md:flex justify-center items-center">
        <div>
          <Loader />
        </div>
        <div>
          <div>
            <Link to="/">
              <img className="w-[10%] mb-14 mx-auto" src={logo} alt="logo" />
            </Link>
          </div>
          <div className="bg-[#181b20] border-[0.5px] p-5 md:p-10 md:w-[500px] fadeIn">
            <div className="text-center text-gray-400 mb-9">
              <h1 className="font-medium text-3xl mb-2">Welcome back</h1>
              <p>Enter your credentials to access your account.</p>
            </div>
            <div>
              <form className="space-y-5" onSubmit={(e) => loginUser(e)}>
                <div>
                  <label
                    htmlFor="email"
                    className="mb-2 text-sm font-medium text-gray-400 block "
                  >
                    Email
                  </label>
                  <input
                    className="w-full bg-gray-500 border text-gray-300 border-gray-700 rounded-md px-4 py-2 outline-none"
                    name="email"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="token"
                    className="mb-2 text-sm font-medium text-gray-400 block "
                  >
                    Token from your authenticator app
                  </label>
                  <input
                    className="w-full bg-gray-500 border text-gray-300 border-gray-700 rounded-md px-4 py-2 outline-none"
                    name="token"
                    required
                    type="text"
                    onChange={(e) => setToken(e.target.value)}
                  />
                </div>
                <div>
                  <button
                    className="w-full bg-[#b2ffce] rounded-md px-4 py-2 outline-none"
                    type="submit"
                  >
                    {loading ? (
                      <CircularProgress color="inherit" size="25px" />
                    ) : (
                      "Submit"
                    )}
                  </button>
                </div>
              </form>
              <p className="text-gray-400 text-center mt-7">
                Don't have an account?{" "}
                <Link to="/signup" className="text-[#b2ffce] underline">
                  Signup
                </Link>
              </p>
            </div>
          </div>
          <div>
            {success === true && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="success">
                  Login Success! You will be redirected to the dashboard soon.
                </Alert>
              </Snackbar>
            )}
            {error && (
              <Snackbar open={true} autoHideDuration={5000}>
                <Alert severity="error">{error}</Alert>
              </Snackbar>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFALogin;
