import React, { useState, useEffect, useContext } from "react";
import SidebarComponent from "../../components/navigation/Sidebar";
import { AuthContext } from "../../context/authContext";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import Loader from "../../components/Loader";
import worldData from "city-state-country";
import client from "../../utils/client";

const Kyc = () => {
  const [states, setStates] = useState([]);
  const [country, setCountry] = useState([]);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [state, setState] = useState("");
  const [address, setAddress] = useState("");
  const [zip, setZip] = useState("");
  const [dob, setDob] = useState("");
  const [docType, setDocType] = useState("");
  const [doc, setDoc] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);
  const [message, setMessage] = useState(null);
  const { user, token } = useContext(AuthContext);
  const countries = worldData.getAllCountries();
  useEffect(() => {
    setStates(worldData.getAllStatesFromCountry(country));
  }, [country]);

  function submitKyc(e) {
    setLoading(true);
    e.preventDefault();
    const data = {
      firstName,
      lastName,
      state,
      country,
      documentType: docType,
      file: doc,
      street: address,
      dob,
      zip,
    };
    const formdata = new FormData();
    for (const [key, value] of Object.entries(data)) {
      formdata.append(key, value);
    }
    client({
      url: "/auth/me/update-kyc",
      data: formdata,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-type": "multipart/formdata",
      },
      method: "PUT",
    })
      .then((res) => {
        setLoading(false);
        setSuccess(true);
        setMessage("Your verification is pending approval.");
      })
      .catch((err) => {
        console.log(err);
        setMessage("An error occured. Please try again later.");

        console.log(err);
        setLoading(false);
        setSuccess(false);
      });
  }
  return (
    <div>
      <div>
        <Loader />
      </div>
      <div>
        <SidebarComponent user={user} />
      </div>
      <div className="p-4 lg:ml-64 bg-[#0a0c0f] min-h-screen">
        <div className="p-4 border border-gray-600 border-dashed min-h-screen rounded-lg dark:border-gray-700 mt-20">
          {user.verified ? (
            <div className="mt-[50vh]">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-16 h-16 text-green-400 mb-2 mx-auto"
              >
                <path
                  fillRule="evenodd"
                  d="M8.603 3.799A4.49 4.49 0 0112 2.25c1.357 0 2.573.6 3.397 1.549a4.49 4.49 0 013.498 1.307 4.491 4.491 0 011.307 3.497A4.49 4.49 0 0121.75 12a4.49 4.49 0 01-1.549 3.397 4.491 4.491 0 01-1.307 3.497 4.491 4.491 0 01-3.497 1.307A4.49 4.49 0 0112 21.75a4.49 4.49 0 01-3.397-1.549 4.49 4.49 0 01-3.498-1.306 4.491 4.491 0 01-1.307-3.498A4.49 4.49 0 012.25 12c0-1.357.6-2.573 1.549-3.397a4.49 4.49 0 011.307-3.497 4.49 4.49 0 013.497-1.307zm7.007 6.387a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
              <h1 className="text-center text-gray-300">
                Your verification is complete.
              </h1>
            </div>
          ) : (
            <div>
              {" "}
              <div>
                <h1 className="text-gray-400 text-xl font-medium">
                  Identity Verification
                </h1>
              </div>
              <div>
                <form
                  onSubmit={submitKyc}
                  className="md:grid grid-cols-3 gap-10 mt-10"
                >
                  <div>
                    <label
                      htmlFor="firstName"
                      className="text-gray-300 text-sm"
                    >
                      First Name *
                    </label>
                    <input
                      name="firstName"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      required
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="lastName" className="text-gray-300 text-sm">
                      Last Name *
                    </label>
                    <input
                      name="lastName"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      required
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="country" className="text-gray-300 text-sm">
                      Country *
                    </label>
                    <select
                      required
                      name="country"
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                    >
                      <option>Select a country</option>
                      {countries.map((country) => {
                        return (
                          <>
                            <option>{country.name}</option>;
                          </>
                        );
                      })}
                    </select>
                  </div>
                  {states.length > 0 && (
                    <div>
                      <label htmlFor="state" className="text-gray-300 text-sm">
                        State *
                      </label>
                      <select
                        required
                        onChange={(e) => {
                          setState(e.target.value);
                        }}
                        name="state"
                        className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                      >
                        <option>Select a state</option>
                        {states.map((state) => {
                          return <option>{state.name}</option>;
                        })}
                      </select>
                    </div>
                  )}

                  <div>
                    <label htmlFor="street" className="text-gray-300 text-sm">
                      Street Address *
                    </label>
                    <input
                      name="street"
                      onChange={(e) => {
                        setAddress(e.target.value);
                      }}
                      required
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="zipCode" className="text-gray-300 text-sm">
                      Postal/Zip Code *
                    </label>
                    <input
                      name="zipCode"
                      onChange={(e) => {
                        setZip(e.target.value);
                      }}
                      required
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    />
                  </div>
                  <div>
                    <label htmlFor="dob" className="text-gray-300 text-sm">
                      Date of birth *
                    </label>
                    <input
                      name="dob"
                      onChange={(e) => {
                        setDob(e.target.value);
                      }}
                      required
                      type="date"
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="documentType"
                      className="text-gray-300 text-sm"
                    >
                      Document Type *
                    </label>
                    <select
                      required
                      onChange={(e) => {
                        setDocType(e.target.value);
                      }}
                      name="state"
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    >
                      <option>Select a document type</option>
                      {/* <option>Voter's Card</option> */}
                      <option>National ID Card</option>
                      <option>Driver's License</option>
                    </select>
                  </div>
                  <div>
                    <label htmlFor="document" className="text-gray-300 text-sm">
                      Document *
                    </label>
                    <input
                      name="document"
                      onChange={(e) => {
                        setDoc(e.target.files[0]);
                      }}
                      type="file"
                      required
                      className="bg-[#181b20] w-full py-3 px-5 text-gray-400 mt-2 outline-none rounded-md"
                    />
                  </div>
                  <div>
                    <button
                      type="submit"
                      className="md:w-1/2 w-full bg-[#b2ffce] px-5 py-2 rounded-md font-medium"
                    >
                      {loading ? (
                        <CircularProgress color="inherit" size="25px" />
                      ) : (
                        "Submit"
                      )}
                    </button>
                  </div>
                </form>
                {success ? (
                  <Snackbar open={true} autoHideDuration={5000}>
                    <Alert severity="success">{message}</Alert>
                  </Snackbar>
                ) : (
                  success === false && (
                    <Snackbar open={true} autoHideDuration={5000}>
                      <Alert severity="error">{message}</Alert>
                    </Snackbar>
                  )
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Kyc;
