import React from "react";
import Loader from "../components/Loader";
import Navbar from "../components/Navbar";
import Footer from "../components/navigation/Footer";
import Livechat from "../components/LiveChat";

const Privacy = () => {
  return (
    <div>
      <div className="bg-[#0a0c0f]">
        <div>
          <Livechat />
        </div>
        <div>
          <Loader />
        </div>
        <Navbar />
        <div className="max-lg:py-20 max-lg:px-5 lg:p-40 text-gray-300 space-y-10">
          <div>
            <h1 className="font-semibold text-3xl">Privacy Policy</h1>
            <div className="px-5 border-l-4 mt-2">
              <p className="font-bold">
                WITH THE FOLLOWING PRIVACY POLICY STATEMENT, WE COMPLY WITH OUR
                STATUTORY DUTY TO INFORM YOU ABOUT THE STORAGE OF DATA, THE TYPE
                OF DATA, THEIR PURPOSE AND OUR IDENTITY. WE ALSO NOTIFY YOU OF
                THE INITIAL REGISTRATION AND THE TYPE OF DATA SUBMITTED.
              </p>
            </div>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl md:text-3xl">Preamble</h1>
            <p>
              We at Algoo Strategies respect and protect the privacy of visitors
              to our websites and our customers. This Privacy Policy describes
              our information handling practices when you access our services
              (as described and governed by our Terms of Use and referred to
              collectively hereinafter as "Services").
              <br />
              Please take a moment to read this Privacy Policy carefully. If you
              have any questions about this Policy, please submit them to
              support@algoostrategies.org.
              <br />
              We may modify this Privacy Policy from time to time and will
              notify you by email (sent to the email address specified in your
              account) and via a notice on our Services prior to the change
              becoming effective, or as otherwise required by law.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              1. ACCEPTANCE OF THIS PRIVACY POLICY
            </h1>
            <p>
              By accessing and using our Services, you signify acceptance to the
              terms of this Privacy Policy. Where we require your consent to
              process your personal information, we will ask for your consent to
              the collection, use, and disclosure of your personal information
              as described further below. We may provide additional
              "just-in-time" disclosures or information about the data
              processing practices of specific Services. These notices may
              supplement or clarify our privacy practices or may provide you
              with additional choices about how we process your data. If you do
              not agree with or you are not comfortable with any aspect of this
              Privacy Policy, you should immediately discontinue access or use
              of our Services.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              2. THE PERSONAL INFORMATION WE COLLECT
            </h1>
            <p>
              Personal information is typically data that identifies an
              individual or relates to an identifiable individual. This includes
              information you provide to us, information which is collected
              about you automatically, and information collected during the
              course of the provision of the Services, including through the
              Know Your Customer (KYC) process. The definition of personal
              information depends on the applicable law based on your physical
              location. Only the definition that applies to your physical
              location will apply to you under this Privacy Policy.
              <br />
              We may collect the following types of information from you:
              <br />
              - Personal Identification Information: Full name, date of birth,
              nationality, gender, signature, utility bills, photographs, phone
              number, home address, and/or email.
              <br />
              - Formal Identification Information: Government issued identity
              document such as Passport, Driver's License, National Identity
              Card, State ID Card, Tax ID number, passport number, driver's
              license details, national identity card details, visa information,
              and/or any other information deemed necessary to comply with our
              legal obligations under financial or anti-money laundering laws.
              <br />
              - Institutional Information: Employer Identification number (or
              comparable number issued by a government), proof of legal
              formation (e.g. Articles of Incorporation), personal
              identification information for all material beneficial owners.
              <br />
              - Transaction Information: Information about the transactions you
              make on our Services, such as trading activity, account history,
              and wallet addresses.
              <br />
              - Employment Information: Office location, job title, and/or
              description of role. Correspondence: Survey responses, information
              provided to our support team or user research team.
              <br />
              - Online Identifiers: Geo location/tracking details, browser
              fingerprint, operating system, browser name and version, and/or
              personal IP addresses.
              <br />- Cookies: Cookies are files with small amounts of data that
              are commonly used as anonymous unique identifiers. These are sent
              to your browser from the website that you visit and are stored on
              your computer’s hard drive. Our website uses these “cookies” to
              collect information and to improve our platform. You have the
              option to either accept or refuse these cookies, and know when a
              cookie is being sent to your computer. If you choose to refuse our
              cookies, you may not be able to use some portions of our Service.
              <br />
              We may collect the following types of information from Public
              Databases, Credit Bureaus and ID Verification Partners like Jumio:
              <br />
              - Government records and publicly available information about you
              to verify you to comply with our legal obligations, such as
              anti-money laundering laws, and to assess risks about you to
              ensure our Services are not used fraudulently or for other illicit
              activities.
              <br />- Public blockchain data to ensure parties utilizing our
              Services are not engaged in illegal or prohibited activity under
              our Terms, and to analyze transaction trends for research and
              development purposes.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              3. ANONYMIZED AND AGGREGATED DATA
            </h1>
            <p>
              Except for this section, none of the other provisions of this
              Privacy Policy applies to anonymized or aggregated customer data
              (i.e., information about our customers that we combine together so
              that it no longer identifies or references an individual
              customer). Algoo Strategies may use anonymized or aggregate
              customer data for any business purpose, including to better
              understand customer needs and behaviors, improve our Services,
              conduct business intelligence and marketing, and detect security
              threats. We may perform our own analytics on anonymized data or
              enable analytics provided by third parties. Types of data we may
              anonymize include transaction data, click-stream data, performance
              metrics, and fraud indicators.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              4. HOW YOUR PERSONAL INFORMATION IS USED
            </h1>
            <p>
              We generally use personal information to create, develop, operate,
              deliver, and improve our Services, content and advertising; and
              for loss prevention, anti-money laundering, and anti-fraud
              purposes. Most of our core Services are subject to laws and
              regulations requiring us to collect, use, and store your personal
              information in certain ways. This includes collection and storage
              of your photo identification. In addition, we use third parties to
              verify your identity by comparing the personal information you
              provide against third-party databases and public records. We have
              no choice but to actively monitor, investigate, prevent, and
              mitigate any potentially prohibited or illegal activities, enforce
              our agreements with third parties, and/or prevent and detect
              violations of our posted user agreement or agreements for other
              Services.
              <br />
              <br />
              We also simply cannot provide you with Services without such
              information. For example, we send administrative or
              account-related information to you to keep you updated about our
              Services, inform you of relevant security issues or updates, or
              provide other transaction-related information. Based on your
              communication preferences, we may send you marketing
              communications (e.g., emails or mobile notifications) to inform
              you about our events or our partner events; to deliver targeted
              marketing; and to provide you with promotional offers. Our
              marketing will be conducted in accordance with your advertising
              marketing preferences and as permitted by applicable law.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              5. LEGAL BASES FOR PROCESSING YOUR INFORMATION
            </h1>
            <p>
              For individuals who are located in the European Economic Area, the
              United Kingdom or Switzerland (collectively “EEA Residents'') at
              the time their personal information is collected, our legal bases
              for processing your information under the EU General Data
              Protection Regulation (“GDPR”) will depend on the personal
              information at issue, the specific context in the which the
              personal information is collected and the purposes for which it is
              used. We generally only process your data where we are legally
              required to, where processing is necessary to perform any
              contracts, we entered with you (or to take steps at your request
              prior to entering into a contract with you), where processing is
              in our legitimate interests to operate our business and not
              overridden by your data protection interests or fundamental rights
              and freedoms, or where we have obtained your consent to do so. In
              some rare instances, we may need to process your personal
              information to protect your vital interests or those of another
              person.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              6. HOW WE PROTECT AND STORE PERSONAL INFORMATION
            </h1>
            <p>
              We understand how important your privacy is, which is why Algoo
              Strategies maintains (and contractually requires third parties it
              shares your information with to maintain) appropriate
              physical,technical and administrative safeguards to protect the
              security and confidentiality of the personal information you
              entrust to us.
              <br />
              <br />
              We may store and process all or part of your personal and
              transactional information in the US and elsewhere in the world
              where our facilities or our service providers are located. We
              protect your personal information by maintaining physical,
              electronic, and procedural safeguards in compliance with the
              applicable laws and regulations.
              <br />
              <br />
              We cannot guarantee that loss, misuse, unauthorized acquisition,
              or al teration of your data will not occur. Please recognize that
              you play a vital role in protecting your own personal information.
              When registering with our Services, it is important to choose a
              password of sufficient length and complexity, to not reveal this
              password to any third-parties, and to immediately notify us if you
              become aware of any unauthorized access to or use of your account.
              <br />
              <br />
              We also cannot ensure or warrant the security or confidentiality
              of information you transmit to us or receive from us by Internet
              or wireless connection, including email, phone, or SMS, since we
              have no way of protecting that information once it leaves and
              until it reaches us. If you have reason to believe that your data
              is no longer secure, please contact us using the contact
              information provided in the “How to contact us” section below.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              7. RETENTION OF PERSONAL INFORMATION
            </h1>
            <p>
              We store your personal information securely throughout the life of
              your Algoo Strategies Account. We will only retain your personal
              information for as long as necessary to fulfill the purposes for
              which we collected it, including for the purposes of satisfying
              any legal, accounting, or reporting obligations or to resolve
              disputes. While retention requirements vary by jurisdiction,
              information about our typical retention periods for different
              aspects of your personal information are described below.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              9. CHILDREN'S PERSONAL INFORMATION
            </h1>
            <p>
              We do not knowingly request to collect personal information from
              any person under the age of 18. If a user submitting personal
              information is suspected of being younger than 18 years of age,
              Algoo Strategies will require the user to close his or her account
              and will not allow the user to continue using our Services. We
              will also take steps to delete the information as soon as
              possible. Please notify us if you know of any individuals under
              the age of 18 using our Services so we can take action to prevent
              access to our Services.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              10. Data transferred out of the EU
            </h1>
            <p>
              We rely primarily on the European Commission’s Standard
              Contractual Clauses to facilitate the international and onward
              transfer of personal information collected in the European
              Economic Area (“EEA”), the United Kingdom and Switzerland
              (collectively “European Personal Information”), to the extent the
              recipients of the European Personal Information are located in a
              country that the EU considers to not provide an adequate level of
              data protection. We may also rely on an adequacy decision of the
              European Commission confirming an adequate level of data
              protection in the jurisdiction of the party receiving the
              information, or derogations in specific situations.
            </p>
          </div>
          <div className="">
            <h1 className="font-semibold text-2xl">
              11. YOUR PRIVACY RIGHTS AND CHOICES
            </h1>
            <p>
              Depending on applicable law where you reside, you may be able to
              assert certain rights related to your personal information
              identified below. If any of the rights listed below are not
              provided under law for your operating entity or jurisdiction,
              Algoo Strategies has absolute discretion in providing you with
              those rights.
              <br />
              <br />
              Your rights to personal information are not absolute. Depending
              upon the applicable law, access to your rights under the
              applicable law may be denied: (a) when denial of access is
              required or authorized by law; (b) when granting access would have
              a negative impact on another's privacy; (c) to protect our rights
              and properties; (d) where the request is frivolous or vexatious,
              or for other reasons.
              <br />
              <br />
              Access and portability. You may request that we provide you a copy
              of your personal information held by us. This information will be
              provided without undue delay subject to a potential fee associated
              with gathering of the information (as permitted by law), unless
              such provision adversely affects the rights and freedoms of
              others. In certain circumstances, you may request to receive your
              personal information in a structured, commonly used and
              machine-readable format, and to have us transfer your personal
              information directly to another data controller.
              <br />
              <br />
              Rectification of incomplete or inaccurate personal information.
              You may request us to rectify or update any of your personal
              information held by Algoo Strategies that is inaccurate. You may
              request to erase your personal information, subject to applicable
              law. If you close your Algoo Strategies Account, we will mark your
              account in our database as "Closed," but will keep certain account
              information, including your request to erase, in our database for
              a period of time as described above. This is necessary to deter
              fraud, by ensuring that persons who try to commit fraud will not
              be able to avoid detection simply by closing their account and
              opening a new account, and to comply with our legal obligations.
              However, if you close your account, your personal information will
              not be used by us for any further purposes, nor shared with third
              parties, except as necessary to prevent fraud and assist law
              enforcement, as required by law, or in accordance with this
              Privacy Policy. In some jurisdictions, applicable law may give you
              the right to restrict or object to us processing or transferring
              your personal information under certain circumstances. We may
              continue to process your personal information if it is necessary
              for the defense of legal claims, or for any other exceptions
              permitted by applicable law.
              <br />
              <br />
              To the extent we can rely on legitimate interest under the
              applicable law, we will only send you information about our
              Services that are similar to those which were the subject of a
              previous sale or negotiations of a sale to you. We will contact
              you by electronic means for marketing purposes only if you have
              consented to such communication. You may raise such objections
              with regard to initial or further processing for purposes of
              direct marketing at any time and free of charge.
            </p>
          </div>
        </div>
        <div className="px-5 md:px-32">
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Privacy;
