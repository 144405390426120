import React from "react";
import { Link } from "react-router-dom";
import fixedYield from "../assets/fixed-yield-mobile.png";

const CallToAction = () => {
  return (
    <div className="overflow-hidden pt-10">
      <div className="bg-gradient-to-r from-[#0a0c0f] via-green-500 to-[#181b20] rounded-xl mt-5 lg:mt-10 max-md:mx-5">
        <div className="grid grid-cols-2 md:gap-9 max-w-[90%] md:max-w-[70%] mx-auto">
          <div className="relative flex items-center justify-between">
            <img
              src={fixedYield}
              alt=""
              className="w-[90%] max-md:-top-8 lg:w-[70%] xl:w-[50%] absolute overflow-y-hidden max-lg:-top-16 lg:-top-20"
            />
            <div className="hidden lg:block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="167"
                height="81"
                viewBox="0 0 167 81"
                fill="none"
                className="-rotate-[160deg] absolute -right-20"
              >
                <script xmlns="" />
                <g clip-path="url(#clip0_15938_81418)">
                  <path
                    d="M6.27108 2.72565C6.28226 1.34516 5.17192 0.217363 3.79108 0.206651C2.41023 0.195938 1.28178 1.30637 1.27061 2.68686L6.27108 2.72565ZM75.7502 72.6521L75.4793 75.1372L75.7502 72.6521ZM166.037 55.9247C166.376 54.5864 165.566 53.2268 164.227 52.8879L142.415 47.3656C141.076 47.0268 139.716 47.837 139.377 49.1753C139.037 50.5136 139.847 51.8732 141.186 52.2121L160.575 57.1207L155.658 76.5064C155.319 77.8447 156.129 79.2043 157.468 79.5432C158.806 79.882 160.166 79.0718 160.506 77.7335L166.037 55.9247ZM3.77085 2.70626C1.27061 2.68686 1.2706 2.68852 1.27058 2.69043C1.27058 2.69138 1.27056 2.69352 1.27055 2.69542C1.27053 2.69922 1.27051 2.70397 1.27049 2.70966C1.27045 2.72104 1.27042 2.73619 1.27044 2.75504C1.27049 2.79273 1.27072 2.84526 1.27144 2.91217C1.27288 3.04599 1.27622 3.23739 1.28376 3.4828C1.29882 3.97354 1.33064 4.6807 1.39744 5.57565C1.53098 7.36483 1.80464 9.90898 2.36523 12.978C3.4851 19.1089 5.75813 27.379 10.3809 35.9173C19.6925 53.1159 38.3933 71.1089 75.4793 75.1372L76.021 70.1671C40.6254 66.3224 23.3365 49.3425 14.7788 33.5364C10.467 25.5724 8.33552 17.8321 7.28453 12.0783C6.75969 9.205 6.50634 6.8382 6.38422 5.20201C6.32318 4.38427 6.29501 3.75015 6.28204 3.32778C6.27556 3.11662 6.27288 2.95851 6.27179 2.85695C6.27125 2.80617 6.2711 2.76955 6.27108 2.74752C6.27106 2.7365 6.27108 2.72913 6.27109 2.72547C6.2711 2.72364 6.27111 2.72273 6.27111 2.72276C6.27111 2.72278 6.2711 2.7235 6.2711 2.72351C6.27109 2.72446 6.27108 2.72565 3.77085 2.70626ZM75.4793 75.1372C124.423 80.4534 160.644 59.9885 164.892 57.4585L162.334 53.1638C158.476 55.4614 123.392 75.3125 76.021 70.1671L75.4793 75.1372Z"
                    fill="url(#paint0_linear_15938_81418)"
                  />
                </g>
                <defs>
                  <linearGradient
                    id="paint0_linear_15938_81418"
                    x1="103.104"
                    y1="72.1224"
                    x2="-7.32525"
                    y2="3.90539"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="white" />
                    <stop
                      offset="0.940625"
                      stop-color="white"
                      stop-opacity="0"
                    />
                  </linearGradient>
                  <clipPath id="clip0_15938_81418">
                    <rect width="167" height="81" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
          </div>
          <div className="py-7 md:py-14 lg:max-w-[60%] md:mx-auto w-full">
            <h1 className="text-lg sm:text-xl md:text-3xl text-gray-300 mb-1 md:mb-2 font-medium">
              Create an account and invest in just a few clicks
            </h1>
            <p className="text-gray-800 font-medium max-md:text-sm">
              Use your email, verify your account and start investing.
            </p>
            <Link to="/dashboard/strategies">
              <button className="bg-[#b2ffce] py-2 md:py-3 px-8 max-md:w-full max-md:text-sm rounded-md mt-4 md:mt-6 font-medium">
                INVEST
              </button>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CallToAction;
