import React from "react";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";

const Timeline = () => {
  return (
    <div>
      <div className="container">
        <div className="flex flex-col md:grid grid-cols-9 mx-auto p-2 text-gray-300">
          {/* <!-- left --> */}
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="flex flex-row-reverse md:contents"
          >
            <div className="bg-[#181b20] hover:scale-[1.05] duration-700 col-start-1 col-end-5 p-4 md:p-6 rounded-xl my-4 ml-auto shadow-md">
              <h3 className="font-medium text-lg mb-1">
                Personalized investment recommendations:
              </h3>
              <p className="font-medium text-gray-400 hover:text-gray-300 duration-700">
                The questionnaire helps to identify your risk tolerance,
                investment objectives, and goals. Based on your responses, Algoo
                Strategies will provide personalized investment recommendations
                that align with your investment profile.
              </p>
            </div>
            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-500 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-[#b2ffce] shadow"></div>
            </div>
          </AnimationOnScroll>
          {/* <!-- right --> */}
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="flex md:contents"
          >
            <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-500 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-[#b2ffce] shadow"></div>
            </div>
            <div className="bg-[#181b20] hover:scale-[1.05] duration-700 col-start-6 col-end-10 p-4 md:p-6 rounded-xl my-4 mr-auto shadow-md">
              <h3 className="font-semibold text-lg mb-1">
                Better decision-making:
              </h3>
              <p className="font-medium text-gray-400 hover:text-gray-300 duration-700">
                By understanding your risk tolerance, you can make more informed
                investment decisions. This can help you avoid making impulsive
                decisions that could lead to financial losses.
              </p>
            </div>
          </AnimationOnScroll>
          {/* <!-- left --> */}
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="flex flex-row-reverse md:contents"
          >
            <div className="bg-[#181b20] hover:scale-[1.05] duration-700 col-start-1 col-end-5 p-4 md:p-6 rounded-xl my-4 ml-auto shadow-md">
              <h3 className="font-semibold text-lg mb-1">Minimize risks:</h3>
              <p className="font-medium text-gray-400 hover:text-gray-300 duration-700">
                Algoo Strategies takes into account your risk tolerance and
                investment goals to provide strategies that minimize risks while
                maximizing returns. This helps you to achieve your investment
                objectives without exposing yourself to undue risks.
              </p>
            </div>
            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-500 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-[#b2ffce] shadow"></div>
            </div>
          </AnimationOnScroll>
          {/* <!-- right --> */}
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="flex md:contents"
          >
            <div className="col-start-5 col-end-6 mr-10 md:mx-auto relative">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-500 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-[#b2ffce] shadow"></div>
            </div>
            <div className="bg-[#181b20] hover:scale-[1.05] duration-700 col-start-6 col-end-10 p-4 md:p-6 rounded-xl my-4 mr-auto shadow-md">
              <h3 className="font-semibold text-lg mb-1">Time-saving:</h3>
              <p className="font-medium text-gray-400 hover:text-gray-300 duration-700">
                The risk tolerance questionnaire is a quick and easy way to
                determine the best investment strategies for your needs. It
                saves you time that you would have otherwise spent researching
                and analyzing various investment options.
              </p>
            </div>
          </AnimationOnScroll>
          {/* <!-- left --> */}
          <AnimationOnScroll
            animateIn="animate__fadeIn"
            className="flex flex-row-reverse md:contents"
          >
            <div className="bg-[#181b20] hover:scale-[1.05] duration-700 col-start-1 col-end-5 p-4 md:p-6 rounded-xl my-4 ml-auto shadow-md">
              <h3 className="font-semibold text-lg mb-1">Education:</h3>
              <p className="font-medium text-gray-400 hover:text-gray-300 duration-700">
                The questionnaire helps you to understand your own risk profile,
                and to learn more about the risks and rewards of different
                investment strategies. This education can help you to make more
                informed investment decisions in the future.
              </p>
            </div>
            <div className="col-start-5 col-end-6 md:mx-auto relative mr-10">
              <div className="h-full w-6 flex items-center justify-center">
                <div className="h-full w-1 bg-green-500 pointer-events-none"></div>
              </div>
              <div className="w-6 h-6 absolute top-1/2 -mt-3 rounded-full bg-[#b2ffce] shadow"></div>
            </div>
          </AnimationOnScroll>
        </div>
      </div>
    </div>
  );
};

export default Timeline;
