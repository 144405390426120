import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import { useNavigate } from "react-router-dom";
import client from "../../utils/client";

const Circle = ({ image, symbol, apy, yieldName }) => {
  const gradientArray = [
    { from: "#E53935", to: "#8E24AA", text: "#F5F5F5" },
    { from: "#2196F3", to: "#3F51B5", text: "#FFFFFF" },
    { from: "#F44336", to: "#FFC107", text: "#FFFFFF" },
    { from: "#4CAF50", to: "#8BC34A", text: "#FFFFFF" },
    { from: "#673AB7", to: "#3F51B5", text: "#000000" },
    { from: "#FFC107", to: "#FF9800", text: "#424242" },
    { from: "#9C27B0", to: "#E91E63", text: "#F5F5F5" },
    { from: "#00BCD4", to: "#009688", text: "#FFFFFF" },
    { from: "#3F51B5", to: "#9C27B0", text: "#F5F5F5" },
    { from: "#607D8B", to: "#795548", text: "#FFFFFF" },
  ];
  const navigate = useNavigate();

  const randomIndex = Math.floor(Math.random() * gradientArray.length);
  const currentGradient = gradientArray[randomIndex];

  const circleStyle = {
    background: `linear-gradient(to bottom right, ${currentGradient.from}, ${currentGradient.to})`,
    color: currentGradient.text,
    width: "150px",
    height: "150px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    fontWeight: "bold",
    cursor: "pointer",
    marginRight: "20px",
  };

  return (
    <div
      onClick={() =>
        navigate(`/fixed-yield?symbol=BINANCE:${symbol}USD&name=${yieldName}`)
      }
      style={circleStyle}
    >
      <div>
        <img alt="" className="w-8 h-8 mx-auto mb-2" src={image} />
        <h1 className="text-sm font-medium">{symbol}</h1>
        <p>{apy} %</p>
        <h1 className="text-sm font-medium">APY</h1>
      </div>
    </div>
  );
};

const Circles = () => {
  const [yields, setYields] = useState([]);

  async function getYields() {
    try {
      const { data } = await client({
        url: "/txns/fixed-yields",
        method: "GET",
      });
      setYields(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getYields();
  }, []);

  return (
    <div className="w-full md:max-w-[80%] lg:max-w-[70%] mx-auto">
      <Marquee className="flex items-center space-x-5">
        {yields?.map((yieldItem) => {
          return (
            <Circle
              symbol={yieldItem.symbol}
              image={yieldItem.image}
              apy={yieldItem.apy}
              yieldName={yieldItem.name}
            />
          );
        })}
      </Marquee>
      <Marquee direction="right" className="flex items-center space-x-5 mt-10">
        {yields?.map((yieldItem) => {
          return (
            <Circle
              symbol={yieldItem.symbol}
              image={yieldItem.image}
              apy={yieldItem.apy}
            />
          );
        })}
      </Marquee>
    </div>
  );
};

export default Circles;
