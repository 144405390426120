import React, { useState, useEffect } from "react";

const Loader = () => {
  const [display, setDisplay] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setDisplay(false);
    }, 4000);
  }, []);
  return (
    <div>
      <div
        className={`fixed top-0 bottom-0 left-0 right-0 bg-black z-50 h-screen w-full ${
          display ? "flex" : "fadeout flex hover:hidden"
        } items-center justify-center`}
      >
        <div>
          <Loading />
        </div>
      </div>
    </div>
  );
};

export function Loading() {
  return (
    <span className="relative isolate inline-flex items-center justify-center">
      <span className="animate-scale absolute z-0 h-8 w-8 rounded-full bg-[#b2ffce]"></span>
      <span className="animate-scale animation-delay-1000 absolute z-10 h-8 w-8 rounded-full bg-[#b2ffcea5]"></span>
    </span>
  );
}
export default Loader;
