import React from "react";

export default function Table({ columns, data }) {
  const tabData = data.map((data, i) => {
    return (
      <tr key={`column${i}`} className="border-b border-b-gray-700">
        {columns.map(({ render }, i2) => (
          <td className="py-4 text-white" key={`data${i}${i2}`}>
            {render(data, i)}
          </td>
        ))}
      </tr>
    );
  });
  return (
    <div className="overflow-x-auto no__scroll">
      <table className="table-auto min-w-[50rem] w-full">
        <thead className="bg-[#181b20]">
          <tr>
            {columns.map((column) => (
              <th>
                <p className="sm:text-sm text-gray-400 font-medium text-left py-2">
                  {column.title}
                </p>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>{tabData}</tbody>
      </table>
    </div>
  );
}
