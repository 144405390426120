import React from "react";
import gerrald from "../assets/gerrald.png";
import markus from "../assets/markus.png";
// import zachary from "../assets/zachary.png";
import frederick from "../assets/frederick.png";
import jonathan from "../assets/jonathan.png";
import martha from "../assets/martha.png";
import bernd from "../assets/bernd.png";
import maya from "../assets/maya.png";
import preston from "../assets/preston.png";
import pathrick from "../assets/pathrick.png";

const Team = () => {
  return (
    <div className="grid grid-cols-2 md:grid-cols-3 gap-8 px-5 md:px-40">
      <div>
        <img
          className="w-[70%] block mx-auto rounded-full"
          src={markus}
          alt="ceo"
        />
        <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
          Markus Pettersson
        </h2>
        <p className="text-[#b2ffce] font-medium text-[15px] text-center">
          CEO & Co-founder
        </p>
      </div>
      {/* <div>
        <a href="https://www.linkedin.com/in/zachary-randall-870096220">
          <img
            className="w-[70%] block mx-auto rounded-full"
            src={zachary}
            alt="ceo"
          />
          <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
            Zachary Randall
          </h2>
          <p className="text-[#b2ffce] font-medium text-[15px] text-center">
            President & Co-founder
          </p>
        </a>
      </div> */}
      <div>
        <a href="www.linkedin.com/in/frederick-welston-b86476211">
          <img
            className="w-[70%] block mx-auto rounded-full"
            src={frederick}
            alt="ceo"
          />
          <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
            Frederick Welston
          </h2>
          <p className="text-[#b2ffce] font-medium text-[15px] text-center">
            C.F.O
          </p>
        </a>
      </div>
      <div>
        <a href="https://www.linkedin.com/in/gerrald-leno-6bb378211">
          <img
            className="w-[70%] block mx-auto rounded-full"
            src={gerrald}
            alt="ceo"
          />
          <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
            Gerrald Leno
          </h2>
          <p className="text-[#b2ffce] font-medium text-[15px] text-center">
            C.T.O
          </p>
        </a>
      </div>
      <div>
        <a href="https://www.linkedin.com/in/jonathan-bakke-572165218">
          <img
            className="w-[70%] block mx-auto rounded-full"
            src={jonathan}
            alt="ceo"
          />
          <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
            Jonathan Blake
          </h2>
          <p className="text-[#b2ffce] font-medium text-[15px] text-center">
            Senior Quant Trader
          </p>
        </a>
      </div>
      <div>
        <img
          className="w-[70%] block mx-auto rounded-full"
          src={martha}
          alt="ceo"
        />
        <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
          Martha Laundgren
        </h2>
        <p className="text-[#b2ffce] font-medium text-[15px] text-center">
          Blockchain Developer
        </p>
      </div>
      <div>
        <a href="https://www.linkedin.com/in/bernd-mansson-a18488201">
          <img
            className="w-[70%] block mx-auto rounded-full"
            src={bernd}
            alt="ceo"
          />
          <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
            Bernd Mansson
          </h2>
          <p className="text-[#b2ffce] font-medium text-[15px] text-center">
            Associate Product Manager
          </p>
        </a>
      </div>
      <div>
        <img
          className="w-[70%] block mx-auto rounded-full"
          src={maya}
          alt="ceo"
        />
        <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
          Maya Lin
        </h2>
        <p className="text-[#b2ffce] font-medium text-[15px] text-center">
          Capital Market Specialist
        </p>
      </div>
      <div>
        <a href="https://www.linkedin.com/in/preston-grinsted-53ab54242">
          <img
            className="w-[70%] block mx-auto rounded-full"
            src={preston}
            alt="ceo"
          />
          <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
            Prestin Grinstead
          </h2>
          <p className="text-[#b2ffce] font-medium text-[15px] text-center">
            Quantitative Research Analyst
          </p>
        </a>
      </div>
      <div>
        <img
          className="w-[70%] block mx-auto rounded-full"
          src={pathrick}
          alt="ceo"
        />
        <h2 className="font-semibold text-gray-400 text-center mt-3 text-2xl">
          Pathrick Viehover
        </h2>
        <p className="text-[#b2ffce] font-medium text-[15px] text-center">
          Associate Partner
        </p>
      </div>
    </div>
  );
};

export default Team;
