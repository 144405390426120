import React, { useState, useEffect } from "react";
import { capitalize } from "@mui/material";
import { useNavigate } from "react-router-dom";
import client from "../../utils/client";

const YieldsTable = () => {
  const [yields, setYields] = useState([]);
  const navigate = useNavigate();

  async function getYields() {
    try {
      const { data } = await client({
        url: `/txns/fixed-yields`,
        method: "GET",
      });
      setYields(data.data);
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(() => {
    getYields();
  }, []);

  return (
    <div>
      <div className="p-1 text-white w-full md:m-4">
        <div>
          <div className="bg-[#181b20] p-2 rounded-lg grid grid-cols-10 gap-9">
            <div className="text-left px-4 py-2 text-sm text-gray-300 col-span-3">
              Name
            </div>
            <div className="text-left px-4 py-2 text-sm text-gray-300">APY</div>
            <div className="text-left px-4 py-2 text-sm text-gray-300 col-span-2">
              Price
            </div>
            <div className="text-left px-4 py-2 text-sm text-gray-300 col-span-2">
              24h %
            </div>
            <div className="text-left px-4 py-2 text-sm text-gray-300"></div>
          </div>
        </div>
        <div className="mt-2">
          {yields?.map((fixedYield) => {
            return (
              <div className="grid grid-cols-10 gap-4 overflow-x-scroll w-full">
                {/* <div className="flex items-center justify-evenly gap-4 overflow-x-scroll w-full border"> */}
                <div className="py-2 font-bold col-span-4">
                  <div className="flex items-center justify-between">
                    <div>
                      <div className="flex items-center space-x-2 mt-1">
                        <div>
                          <img
                            className="rounded-full w-6 h-6"
                            src={fixedYield.image}
                            alt="strategy"
                          />
                        </div>
                        <div className="block md:flex items-center md:space-x-2">
                          <div>
                            <h1 className="text-gray-200 uppercase text-lg max-md:text-base font-medium">
                              {fixedYield.symbol}
                            </h1>
                          </div>
                          <div>
                            <h1 className="text-gray-400 font-normal text-sm max-md:-mt-1">
                              {capitalize(fixedYield.name)}
                            </h1>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="py-2">
                  <h1 className="text-xl max-md:text-sm text-gray-300 mt-1">
                    {fixedYield.apy} %
                  </h1>
                </div>
                <div className="py-2 col-span-2">
                  <h1 className="text-xl text-gray-300 max-md:text-sm mt-1 tracking-wider">
                    ${fixedYield.price}
                  </h1>
                </div>
                <div className="py-2 col-span-2">
                  <div className="md:flex items-center md:space-x-2 mt-1">
                    <h1
                      className={`${
                        fixedYield.pricePercentageChange.includes("-")
                          ? "text-red-500"
                          : "text-green-500"
                      }`}
                    >
                      {fixedYield.pricePercentageChange} %
                    </h1>
                    <p className="text-gray-500 max-md:text-sm font-medium">
                      (${fixedYield.priceChange})
                    </p>
                  </div>
                </div>
                <div className="py-2">
                  <button
                    onClick={() => {
                      navigate(
                        `/fixed-yield?symbol=BINANCE:${fixedYield.symbol}USD&name=${fixedYield.name}`
                      );
                    }}
                    className="py-1 px-4 max-[640px]:my-1 w-fit text-gray-700 bg-[#b2ffce] rounded-md font-medium"
                  >
                    Deposit
                  </button>
                </div>
                {/* </div> */}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default YieldsTable;
